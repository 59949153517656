import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import axios from 'axios';
import { API, GOOGLE_MAP, RAZOR_PAY } from '../../utils/Api';
import { Box, Typography, Paper, Hidden, Dialog, Grid, useMediaQuery, TextField, Button, Stack, IconButton, ClickAwayListener, Tooltip, DialogContent } from '@mui/material';
import CartItem from '../../components/CartItem';
import { useLocation, useNavigate } from 'react-router-dom';
import { FmdGood, Home, LocationOnOutlined, MyLocation, WorkRounded, AddCircleOutline, WorkOutlineOutlined, HomeRounded, InfoOutlined } from '@mui/icons-material';
import PickUpCartComponent from '../../components/PickUpCartComponent';
import { useDispatch, useSelector } from 'react-redux';
import { setData } from '../../store/Cart/cartSlice';
import useRazorpay from "react-razorpay";
import CouponsComponent from '../../components/CouponsComponent';
import { QRCodeSVG } from 'qrcode.react';
import Images from '../../utils/Images';
import PickUpTime from '../../components/PickUpTime';
import toast from 'react-hot-toast';
import PerfectCombo from '../../components/PerfectCombo';
import { Player } from '@lottiefiles/react-lottie-player';
import { useTheme } from '@emotion/react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import DeliveryAddressComponent from '../../components/DeliveryAddressComponent';
import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';

const CartScreen = () => {
  const [error, setError] = useState(null);
  const token = localStorage.getItem("token");
  const orderType = localStorage.getItem("selectedOrderType");
  // const address = useSelector((state) => state.address);
  const [refreshFlag, setRefreshFlag] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector(state => state?.cart?.data);
  const [store, setStore] = useState([]);
  const [userData, setUserData] = useState([]);
  const [couponData, setCouponData] = useState([]);
  const [Razorpay] = useRazorpay();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [orderDetailsModal, setOrderDetailsModal] = useState(false);
  const [paymentButtonDisabled, setPaymentButtonDisabled] = useState(true);
  const [orderDetailData, setOrderDetailData] = useState([]);
  const [orderCreateData, setOrderCreateData] = useState([]);
  const [razorPayData, setRazorPayData] = useState([]);
  const [pickUpTimeKey, setPickUpTimeKey] = useState(0);
  const activePickTime = useSelector(state => state.time.activePickTime);  
  const arrivalTimeDurationInMinutes = useSelector(state => state.time.newDuration);
  const [outletData, setOutletData] = useState(JSON.parse(localStorage.getItem('selectedStoreData')));
  const [address, setAddress] = useState(JSON.parse(localStorage.getItem('pickedAddress')));
  const location = useLocation();
  const [playConfetti, setPlayConfetti] = useState(false);
  const theme = useTheme();
  const matchesMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const [selectedOrderType, setSelectedOrderType] = useState(localStorage.getItem('selectedOrderType'));
  const [activeChip, setActiveChip] = useState('HOME');
  const [showAddressNameField, setShowAddressNameField] = useState(false);
  const [addAddressModal, setAddAddressModal] = useState(false);
  const [selectedDeliveryAddressType, setSelectedDeliveryAddressType] = useState('');
  const [addressUpdated, setAddressUpdated] = useState(false);
  const [deliveryAddressId, setDeliveryAddressId] = useState('');
  const [countdown, setCountdown] = useState(30);
  const [showBox, setShowBox] = useState(true); 
  const [orderText, setOrderText] = useState("Placed");
  const mapRef = useRef(null);
  const [currentCoordinates, setCurrentCoordinates] = useState({ lat: parseFloat(orderDetailData?.order?.deliveryDetails?.coordinates?.latitude), lng: parseFloat(orderDetailData?.order?.deliveryDetails?.coordinates?.longitude) });
  const [outlet, setOutlet] = useState({ lat: parseFloat(orderDetailData?.order?.outlet?.coordinates?.latitude), lng: parseFloat(orderDetailData?.order?.outlet?.coordinates?.longitude) });
  const [openTaxTooltip, setTaxOpenTooltip] = useState(false);
  const [openSuccessTaxTooltip, setSuccessTaxOpenTooltip] = useState(false);
  const [outletMarker, setOutletMarker] = useState(Images.xpanseMapMarker);
  const [orderCancelModal, setOrderCancelModal] = useState(false);
  const [levelUpgraded, setLevelUpgraded] = useState(false);
  const [levelUpgradeModal, setLevelUpgradeModal] = useState(false);
  const [couponSavedModal, setCouponSaved] = useState(false);
  const [allRewards, setAllRewards] = useState([]);
  const [paymentProccessingLoading, setPaymentProccessingLoading] = useState(false);

  // console.log("active pick time", activePickTime);

  const handleRefreshPickTime = () => {
    setPickUpTimeKey(prevKey => prevKey + 1); // Refresh PickUpTime component
  };

  function convertTo24Hour(time) {
    const [timePart, modifier] = time?.split(' ');
    let [hours, minutes] = timePart?.split(':');
    if (hours === '12') {
      hours = '00';
    }
    if (modifier?.toLowerCase() === 'pm') {
      hours = parseInt(hours, 10) + 12;
    }
    return `${hours}:${minutes}`;
  }

  const convertedTime = convertTo24Hour(activePickTime);
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    const dayOfWeek = days[date.getUTCDay()];
    const month = months[date.getUTCMonth()];
    const year = date.getUTCFullYear() % 100;
    const day = date.getUTCDate();
    return `${dayOfWeek}, ${month}-${day}-${year}`;
  };

  const handleOpenPayment = () => {
    setOpenPayment(true)
  }

  const handleClosePayment = () => {
    setOpenPayment(false)
  }

  const handleOpenOrderDetailsModal = () => {
    setOrderDetailsModal(true);
  }

  const handleCloseOrderDetailsModal = () => {
    setOrderDetailsModal(false);
    if (!levelUpgraded) {
      window.location.reload();
    } else {
      setLevelUpgradeModal(true);
    }
  }

  const handleItemDeleted = () => {
    setRefreshFlag((prevFlag) => !prevFlag);
  };

  // const fetchCartData = async () => {
  //   try {
  //     const response = await axios.get(`${API.BASE_URL}cart/userCart/${outletData._id}/${orderType}`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     dispatch(setData(response?.data));
  //     console.log('Cart data fetch', response.data);
  //     setCouponData(response?.data?.data?.cart?.coupon);
  //   } catch (error) {
  //     dispatch(setData([]));
  //     setError(error);
  //     console.log('Cart Screen Error', error?.response?.data);
  //     if (error?.response?.data?.isUserDeleted === true ) {
  //       localStorage.clear();
  //       console.log('Local storage cleared due to user deletion.');
  //       window.location.reload();
  //     }
  //   }
  // }; 

  const fetchCartData = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      // console.log('Token not found. Skipping API call.');
      return;
    }
    try {
      const response = await axios.get(
        `${API.BASE_URL}cart/userCart/${outletData._id}/${orderType}`, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(setData(response?.data));
      console.log('Cart data fetched', response.data);
      setCouponData(response?.data?.data?.cart?.coupon);
    } catch (error) {
      dispatch(setData([]));
      setError(error);
      console.log('Cart Screen Error', error?.response?.data);
      if (error?.response?.data?.isUserDeleted === true) {
        localStorage.clear();
        console.log('Local storage cleared due to user deletion.');
        window.location.reload();
      }
    }
  };
  
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        fetchCartData();
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  
  const createOrder = async () => {
    setStore(JSON.parse(localStorage.getItem('selectedStoreData')));
    // console.log(store._id);
    try {
      const requestBody = {
        ordersType: orderType,
        paymentMode: "ONLINE",
        outlet: store._id,
        // readyTime: activePickTime
        readyTime: convertedTime,
      };
      const response = await axios.post(`${API.BASE_URL}order/create`, requestBody, {
        headers: {
          Authorization : `Bearer ${token}`
        }
      });
      // console.log("Order created successfully", response.data);
      setOrderCreateData(response?.data?.data);
    } catch (error) {
      console.error('Error while making the POST request:', error?.response);
      toast.error(error?.response?.data?.data?.title);
    }
  };

  // useEffect(() => {
  //   const selectedStoreData = localStorage.getItem('selectedStoreData');
  //   const userDataLogin = localStorage.getItem('userDataLogin');
  //   const selectedAddress = localStorage.getItem('selectedAddress');
  //   if (selectedStoreData) {
  //     setStore(JSON.parse(selectedStoreData));
  //     fetchCartData();
  //     setOutletData(JSON?.parse(selectedStoreData));
  //   }
  //   if (userDataLogin) {
  //     setUserData(JSON?.parse(userDataLogin));
  //   }
  //   setSelectedDeliveryAddressType(localStorage?.getItem('selectedDeliveryAddressType'));
  //   if (selectedAddress) {
  //     try {
  //       const address = JSON?.parse(selectedAddress);
  //       setDeliveryAddressId(address?._id);
  //     } catch (error) {
  //       console.error('Error parsing selectedAddress:', error);
  //     }
  //   }
  //   fetchData();
  // }, []);
  
  useEffect(() => {
    const selectedStoreData = localStorage.getItem('selectedStoreData');
    const userDataLogin = localStorage.getItem('userDataLogin');
    const selectedAddress = localStorage.getItem('selectedAddress');
    
    // Check if selectedStoreData exists and is not "undefined"
    if (selectedStoreData && selectedStoreData !== 'undefined') {
      try {
        const parsedStoreData = JSON.parse(selectedStoreData);
        setStore(parsedStoreData);
        setOutletData(parsedStoreData);
        fetchCartData();
      } catch (error) {
        console.error('Error parsing selectedStoreData:', error);
      }
    }
    
    // Check if userDataLogin exists and is not "undefined"
    if (userDataLogin && userDataLogin !== 'undefined') {
      try {
        setUserData(JSON.parse(userDataLogin));
      } catch (error) {
        console.error('Error parsing userDataLogin:', error);
      }
    }
  
    // Safely get selectedDeliveryAddressType
    setSelectedDeliveryAddressType(localStorage.getItem('selectedDeliveryAddressType'));
  
    // Check if selectedAddress exists and is not "undefined"
    if (selectedAddress && selectedAddress !== 'undefined') {
      try {
        const address = JSON.parse(selectedAddress);
        setDeliveryAddressId(address?._id);
      } catch (error) {
        console.error('Error parsing selectedAddress:', error);
      }
    }
  
    fetchData();
  }, []);
  
  const handlePayment = () => {
    const orderType = localStorage.getItem("selectedOrderType");
    setStore(JSON.parse(localStorage.getItem('selectedStoreData')));
    const isSmallScreen = window.innerWidth <= 960;
    if (isSmallScreen) {
      navigate('/paymentScreen', { state: { grandTotal: data.data.cart.grandTotal, storeId: store._id  } });
    } else {
      createOrder();
      setOpenPayment(true);
      console.log("Successfully Swiped!", data.data.cart.grandTotal);
    }
    console.log(store._id, orderType);
  };

  const handlePaymentType = () => {
    switch (selectedPaymentMethod) {
      case 'UPI':
        handleUpiPayment();
        break;
      case 'Card':
        handleCardPayment();
        break;
      case 'NetBanking':
        handleNetBankingPayment();
        break;
      default:
        break;
    }
  };

  const handleAccordionChange = (method) => {
    setSelectedPaymentMethod(method);
    setPaymentButtonDisabled(false);
  };

  const handleUpiPayment = useCallback(async () => {
    setPaymentProccessingLoading(true);
    try {
      const options = {
          key: RAZOR_PAY.RAZOR_PAY_KEY,
          order_id: orderCreateData.order.id,
          amount: orderCreateData.order.amount,
          currency: "INR",
          name: "Xpanse",
          theme: {
            hide_topbar: false,
            color: "#1DA1F2",
            backdrop_color: "rgba(29, 161, 242, 0.5)",
          },
          prefill: {
            contact: userData.mobile,
            email: userData.email,
            method: "upi",
          },
          handler: function(response) {
            console.log("Payment Successfull", response);
            setRazorPayData(response);
            handleClosePayment();
            // createOrder();
            toast.success("Payment Successfull!!!");
            verifyPayment(response);
          }
      };
      if (Razorpay) {
        const rzpay = new Razorpay(options);
        rzpay.open(options);
        rzpay.on("payment.failed", function (response) {
          console.log(response.error.code);
          console.log(response.error.description);
          console.log(response.error.source);
          console.log(response.error.step);
          console.log(response.error.reason);
        });
      }
    } catch (error) {
      console.error('Error fetching cart data:', error);
      toast.error("Please try after sometime.");
    } finally {
      setPaymentProccessingLoading(false);
    }
  }, [Razorpay, data, fetchCartData]);
  
  const handleCardPayment = useCallback(async () => {
    setPaymentProccessingLoading(true);
    try {
      await fetchCartData();
      const options = {
          key: RAZOR_PAY.RAZOR_PAY_KEY,
          order_id: orderCreateData.order.id,
          amount: orderCreateData.order.amount,
          currency: "INR",
          name: "Xpanse",
          theme: {
            hide_topbar: false,
            color: "#1DA1F2",
            backdrop_color: "rgba(29, 161, 242, 0.5)",
          },
          prefill: {
            contact: userData.mobile,
            email: userData.email,
            method: "card",
          },
          handler: function(response) {
            console.log("Payment Successfull", response);
            setRazorPayData(response);
            handleClosePayment();
            // createOrder();
            toast.success("Payment Successfull!!!");
            verifyPayment(response);
          }
      };
      if (Razorpay) {
        const rzpay = new Razorpay(options);
        rzpay.open(options);
        rzpay.on("payment.failed", function (response) {
          console.log(response.error.code);
          console.log(response.error.description);
          console.log(response.error.source);
          console.log(response.error.step);
          console.log(response.error.reason);
        });
      }
    } catch (error) {
      console.error('Error fetching cart data:', error);
      toast.error("Please try after sometime.");
    } finally {
      setPaymentProccessingLoading(false);
    }
  }, [Razorpay, data, fetchCartData]);

  const handleNetBankingPayment = useCallback(async () => {
    setPaymentProccessingLoading(true);
    try {
      await fetchCartData();
      const options = {
        key: RAZOR_PAY.RAZOR_PAY_KEY,
        order_id: orderCreateData.order.id,
        amount: orderCreateData.order.amount,
        currency: "INR",
        name: "Xpanse",
        prefill: {
          contact: userData.mobile,
          email: userData.email,
          method: "netbanking",
        },
        theme: {
          hide_topbar: false,
          color: "#1DA1F2",
          backdrop_color: "rgba(29, 161, 242, 0.5)",
        },
        handler: function (response) {
          console.log("Payment Successfull", response);
          setRazorPayData(response);
          handleClosePayment();
          // createOrder();
          toast.success("Payment Successfull!!!");
          verifyPayment(response);
        },
      };
      if (Razorpay) {
        const rzpay = new Razorpay(options);
        rzpay.open(options);
        rzpay.on("payment.failed", function (response) {
          console.log(response.error.code);
          console.log(response.error.description);
          console.log(response.error.source);
          console.log(response.error.step);
          console.log(response.error.reason);
        });
      }
    } catch (error) {
      console.error('Error fetching cart data:', error);
      toast.error("Please try after sometime.");
    } finally {
      setPaymentProccessingLoading(false);
    }
  }, [Razorpay, data, fetchCartData]);

  const verifyPayment = async (razorPayData) => {
    setPaymentProccessingLoading(true);
    setStore(JSON.parse(localStorage.getItem('selectedStoreData')));
    // console.log(razorPayData);
    try {
      const requestBody = {
        razorpayOrderId: razorPayData.razorpay_order_id,
        razorpayPaymentId:  razorPayData.razorpay_payment_id,
        razorpaySignature: razorPayData.razorpay_signature,
        ordersType: orderType,
        paymentMode: "ONLINE",
        outlet: store._id,
        readyTime: convertedTime,
        arrivalTimeDurationInMinutes: arrivalTimeDurationInMinutes
        // ...(orderType === "PICKUP" && { readyTime: convertedTime, arrivalTimeDurationInMinutes: arrivalTimeDurationInMinutes })
      };
      if (orderType !== "PICKUP") {
        requestBody.deliveryDetails = deliveryAddressId;
      }
      const response = await axios.post(`${API.BASE_URL}order/verifyPayment`, requestBody, {
        headers: {
          Authorization : `Bearer ${token}`
        }
      });
      setOrderDetailData(response.data.data);
      // console.log("Order Payment Verified successfully", response.data);
      if (response?.data?.data?.levelUpgradedTo !== null) {
        setLevelUpgraded(true);
        // getAllRewardsInfo();
      } else {
        setLevelUpgraded(false);
      }
      handleOpenOrderDetailsModal();
      setShowBox(true);
      setCountdown(30);
      const timerId = setInterval(() => {
          setCountdown(currentCount => {
              if (currentCount <= 1) {
                  clearInterval(timerId);
                  setShowBox(false); 
                  return 0;
              }
              return currentCount - 1;
          });
      }, 1000);
      return () => clearInterval(timerId);
    } catch (error) {
      console.error('Error while making the POST Order Payment Verified request:', error?.response);
    } finally {
      setPaymentProccessingLoading(false);
    }
  };

  useEffect(() => {
    setPickUpTimeKey(prevKey => prevKey + 1);
  }, [data]);

  const refreshCartData = async () => {
    await fetchCartData();
  };

  useEffect(() => {
    if (location.state?.couponApplied) {
      setPlayConfetti(true);
      setCouponSaved(true);
      const timer = setTimeout(() => {
        setPlayConfetti(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [location.state]);
  
  useEffect(() => {
    if (!token) {
      if (!matchesMdUp) {
        navigate('/mobileNumber');
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [token, navigate]);

  const validationSchema = Yup.object().shape({
    flat: Yup.string().required('Flat / H. No. / Floor No. / Building Name is required').matches(/^[A-Za-z0-9][A-Za-z0-9 ,.'-]*$/, 'Cannot contain a special character').min(2, 'Must be at least 2 characters').trim(),
    street: Yup.string().required('Street Address is required').matches(/^[A-Za-z0-9][A-Za-z0-9 ,.'-]*$/, 'Cannot contain a special character').min(2, 'Must be at least 2 characters').trim(),
    receiverName: Yup.string().required('Name is required').matches(/^[A-Za-z][A-Za-z'-]* ?([A-Za-z'-]+ ?)*$/, 'Name must contain only letters, spaces, hyphens, and apostrophes').min(2, 'Name must be at least 2 characters').trim(),
    receiverContact: Yup.string().required("Receiver's Contact is required").matches(/^[6789][0-9]{9}$/, 'Must be a 10 digit number starting with 6, 7, 8, or 9').trim(),
    addressName: showAddressNameField ? Yup.string().required('Address Name is required').matches(/^[A-Za-z0-9][A-Za-z0-9 ,.'-]*$/, 'Cannot contain a special character').min(2, 'Must be at least 2 characters').trim() : Yup.string(),
  });

  const initialValues = {
    flat: '',
    street: '',
    landmark: '',
    receiverName: '',
    receiverContact: '',
    addressName: ''
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    console.log('Submitted values:', values);
    setSubmitting(false);
    try {
      const requestBody = {
        type: activeChip,
        addressLine1: values.flat,
        addressLine2: values.street,
        // landmark: values.landmark,
        receiverName: values.receiverName,
        receiverContact: values.receiverContact,
        pincode: address?.address?.postcode,
        city: address?.address?.suburb || address?.address?.village || address?.address?.town || address?.address?.county,
        state: address?.address?.state,
        country: "India",
        coordinates: {                     
          latitude: address?.lat,
          longitude: address?.lon
        }
      };
      if (values.landmark) {
        requestBody.landmark = values.landmark;
      }
      if (activeChip === "OTHER") {
        requestBody.addressName = values.addressName;
      }
      const response = await axios.post(`${API.BASE_URL}delivery-details`, requestBody, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Address added successfully:', response.data.data.dbResponse);
      handleAddAddressModalClose();
      localStorage.setItem('selectedAddress', JSON.stringify(response.data.data.dbResponse));
      localStorage.setItem('selectedDeliveryAddressType', 'EXISTING');
      toast.success("Address added Successfully");
      fetchData();
      setSelectedDeliveryAddressType('EXISTING');
    } catch (error) {
      console.error('Error adding address:', error);
      toast.error(error.response.data.error);
    }
  };

  const fetchData = async () => {
    setAddressUpdated(prevState => !prevState);
  };

  const handleChipClick = (chipName) => {
    setActiveChip(chipName);
    console.log(chipName);
    if (chipName === 'OTHER') {
      setShowAddressNameField(true);
    } else {
      setShowAddressNameField(false);
    }
  };

  const handleAddAddressModalOpen = () => {
    setAddAddressModal(true);
  };

  const handleAddAddressModalClose = () => {
    setAddAddressModal(false);
  };

  const containerStyle = {
    width: "95%",
    height: "55%",
    marginTop: "7%",
    border: "4px solid #fff",
    borderRadius: "12px"
  };

  // const totalMrp = useMemo(() => {
  //   return data?.data?.cartItems?.reduce((acc, item) => {
  //     const totalMrp = (item?.product?.mrp) * item?.quantity;
  //     return acc + totalMrp;
  //   }, 0);
  // }, [data?.data?.cartItems]);

  const totalMrp = useMemo(() => {
    return data?.data?.cartItems?.reduce((acc, item) => {
      const productMrp = (item?.product?.mrp || 0) * (item?.quantity || 0);
      const addonsMrp = item?.addons?.reduce((addonAcc, addon) => {
        return addonAcc + ((addon?.mrp || 0) * (item?.quantity || 0));
      }, 0);
      return acc + productMrp + addonsMrp;
    }, 0);
  }, [data?.data?.cartItems]);
  
  const totalMrpOrder = useMemo(() => {
    return orderDetailData?.orderItems?.reduce((acc, item) => {
      const productMrp = (item?.product?.mrp || 0) * (item?.quantity || 0);
      const addonsMrp = item?.addons?.reduce((addonAcc, addon) => {
        return addonAcc + ((addon?.mrp || 0) * (item?.quantity || 0));
      }, 0);
      return acc + productMrp + addonsMrp;
    }, 0);
  }, [orderDetailData?.orderItems]);

  // const totalOfferPrice = useMemo(() => {
  //   return data?.data?.cartItems?.reduce((acc, item) => {
  //     const totalOfferPrice = (item?.product?.offerPrice) * item?.quantity;
  //     return acc + totalOfferPrice;
  //   }, 0);
  // }, [data?.data?.cartItems]);

  const totalOfferPrice = useMemo(() => {
    return data?.data?.cartItems?.reduce((acc, item) => {
      const productOfferPrice = (item?.product?.offerPrice || 0) * (item?.quantity || 0);
      const addonsOfferPrice = item?.addons?.reduce((addonAcc, addon) => {
        return addonAcc + ((addon?.offerPrice || 0) * (item?.quantity || 0));
      }, 0);
      return acc + productOfferPrice + addonsOfferPrice;
    }, 0);
  }, [data?.data?.cartItems]);  
  
  const totalOfferPriceOrder = useMemo(() => {
    return orderDetailData?.orderItems?.reduce((acc, item) => {
      const productOfferPrice = (item?.product?.offerPrice || 0) * (item?.quantity || 0);
      const addonsOfferPrice = item?.addons?.reduce((addonAcc, addon) => {
        return addonAcc + ((addon?.offerPrice || 0) * (item?.quantity || 0));
      }, 0);
      return acc + productOfferPrice + addonsOfferPrice;
    }, 0);
  }, [orderDetailData?.orderItems]);

  // const totalSavings = useMemo(() => {
  //   return data?.data?.cartItems?.reduce((acc, item) => {
  //     const savingsPerItem = (item?.product?.mrp - item?.product?.offerPrice) * item?.quantity;
  //     return acc + savingsPerItem;
  //   }, 0);
  // }, [data?.data?.cartItems]);

  const totalSavings = useMemo(() => {
    return data?.data?.cartItems?.reduce((acc, item) => {
      const savingsPerProduct = (item?.product?.mrp - item?.product?.offerPrice) * (item?.quantity || 0);
      const savingsPerAddons = item?.addons?.reduce((addonAcc, addon) => {
        return addonAcc + ((addon?.mrp - addon?.offerPrice) * (item?.quantity || 0));
      }, 0);
      return acc + savingsPerProduct + savingsPerAddons;
    }, 0);
  }, [data?.data?.cartItems]);  

  const totalSizeUpgradeAmount = useMemo(() => {
    return data?.data?.cartItems?.reduce((acc, item) => {
      return acc + (item?.sizeUpgradePayload?.newAddonItemOfferPrice || 0) - (item?.sizeUpgradePayload?.prevAddonItemOfferPrice || 0);
    }, 0);
  }, [data?.data?.cartItems]);

  const totalCouponDiscount = data?.data?.cartItems.reduce((total, item) => {
    return total + (item?.discount);
  }, 0);

  const totalTaxAmount = (data?.data?.cart?.totalTaxAmount || 0) + (data?.data?.additionalCharges?.GSTPackingCharges || 0) +  (data?.data?.additionalCharges?.GSTPlatformFees || 0);
  const totalTaxAmountSuccess = (orderDetailData?.order?.totalTaxAmount || 0) + (orderDetailData?.order?.GSTPackingCharges || 0) +  (orderDetailData?.order?.GSTPlatformFees || 0);
  const totalSum = totalTaxAmount + totalOfferPrice;
  const formattedSum = totalSum.toFixed(2);
  const totalShippingAmount = data?.data?.cart?.deliveryCharges;
  const totalQuantity = data?.data?.cartItems?.reduce((acc, item) => acc + item?.quantity, 0) || 0;
  const totalQuantityInOrder = orderDetailData?.orderItems?.reduce((total, item) => { return total + item?.quantity; }, 0);
  // const isAnyProductDeleted = data?.data?.cartItems?.some(item => item.isDelete);
  // const isAnyProductExhausted = data?.data?.cartItems.some(item => item?.product?.isExhausted);
  const isAnyProductDeletedOrExhausted = data?.data?.cartItems?.some(item => item?.product?.isDelete || item?.product?.isExhausted);

  const handleCancelOrder = async () => {
    setShowBox(true);
    try {
      const response = await axios.put(`${API.BASE_URL}order/cancel`, 
        { "_id": orderDetailData.order._id },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
      });
      console.log('Order cancelled:', response.data);
      setShowBox(false);
      setOrderText("Cancelled");
      setOrderDetailsModal(false);
      setOrderCancelModal(true);
    } catch (error) {
      console.error('Failed to cancel order:', error);
    }
  };

  useEffect(() => {
      const timer = setTimeout(() => {
        setCurrentCoordinates({ lat: parseFloat(orderDetailData?.order?.deliveryDetails?.coordinates?.latitude), lng: parseFloat(orderDetailData?.order?.deliveryDetails?.coordinates?.longitude) });
        setOutlet({ lat: parseFloat(orderDetailData?.order?.outlet?.coordinates?.latitude), lng: parseFloat(orderDetailData?.order?.outlet?.coordinates?.longitude) });
      }, 1000);
      return () => clearTimeout(timer);
  }, [orderDetailData]);

  useEffect(() => {
    if (!token || !orderDetailData?.order?.deliveryOrderId) {
      // console.log('Token or order details are missing. Skipping API call.');
      return;
    }
    const fetchCurrentCoordinates = async () => {
      try {
        const response = await axios.get(`${API.BASE_URL}order/tracking/${orderDetailData?.order?.deliveryOrderId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setOutlet({ lat: parseFloat(orderDetailData?.order?.outlet?.coordinates?.latitude), lng: parseFloat(orderDetailData?.order?.outlet?.coordinates?.longitude)});
        setOutletMarker(Images.xpanseMapMarker);
        console.log('Successfully fetched current coordinates:', response.data.data);
      } catch (error) {
        console.error('Error while fetching current coordinates:', error);
      }
    };
    fetchCurrentCoordinates();
    const intervalId = setInterval(fetchCurrentCoordinates, 300000);
    return () => {
      clearInterval(intervalId);
    };
  }, [orderDetailsModal, orderDetailData?.order?.deliveryOrderId, token]);

  useEffect(() => {
    if (orderDetailData && orderDetailData?.order?.deliveryDetails?.coordinates && mapRef.current) {
      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend(new window.google.maps.LatLng(currentCoordinates.lat, currentCoordinates.lng));
      bounds.extend(new window.google.maps.LatLng(outlet.lat, outlet.lng));
      mapRef.current.fitBounds(bounds);
    }
  }, [orderDetailData, currentCoordinates, outlet]);

  const onLoad = map => {
    mapRef.current = map;
  };

  const onUnmount = () => {
    mapRef.current = null;
  };

  const handleTaxTooltipOpen = () => {
    setTaxOpenTooltip(true);
  };

  const handleTaxTooltipClose = () => {
    setTaxOpenTooltip(false);
  };

  const taxTooltipContent = (
    <Box>
      <Typography variant="body2" padding={'2px 0 2px 0'} sx={{ display: 'flex', justifyContent: 'space-between'}} component="p">
        Base Tax: <span>₹{data?.data?.cart?.totalTaxAmount}</span>
      </Typography>
      {selectedOrderType === 'DELIVERY' && (
        <Typography variant="body2" padding={'2px 0 2px 0'} sx={{ display: 'flex', justifyContent: 'space-between'}} component="p">
          GST Packing Charges: <span>₹{data?.data?.additionalCharges?.GSTPackingCharges}</span>
        </Typography>
      )}
      <Typography variant="body2" padding={'2px 0 2px 0'} sx={{ display: 'flex', justifyContent: 'space-between'}} component="p">
        GST Platform Fees: <span>₹{data?.data?.additionalCharges?.GSTPlatformFees}</span>
      </Typography>
      <Typography variant="body2" padding={'2px 0 2px 0'} component="p" sx={{ borderTop: '1px solid #fff', display: 'flex', justifyContent: 'space-between' }}>
        Total Tax: <span>₹{totalTaxAmount.toFixed(2)}</span>
      </Typography>
    </Box>
  );

  const handleSuccessTaxTooltipOpen = () => {
    setTaxOpenTooltip(true);
  };

  const handleSuccessTaxTooltipClose = () => {
    setTaxOpenTooltip(false);
  };

  const successTaxTooltipContent = (
    <Box>
      <Typography variant="body2" padding={'2px 0 2px 0'} sx={{ display: 'flex', justifyContent: 'space-between'}} component="p">
        Base Tax: <span>₹{orderDetailData?.order?.totalTaxAmount}</span>
      </Typography>
      {orderDetailData?.order?.ordersType === 'DELIVERY' && (
        <Typography variant="body2" padding={'2px 0 2px 0'} sx={{ display: 'flex', justifyContent: 'space-between'}} component="p">
          GST Packing Charges: <span>₹{orderDetailData?.order?.GSTPackingCharges}</span>
        </Typography>
      )}
      <Typography variant="body2" padding={'2px 0 2px 0'} sx={{ display: 'flex', justifyContent: 'space-between'}} component="p">
        GST Platform Fees: <span>₹{orderDetailData?.order?.GSTPlatformFees}</span>
      </Typography>
      <Typography variant="body2" padding={'2px 0 2px 0'} component="p" sx={{ borderTop: '1px solid #fff', display: 'flex', justifyContent: 'space-between' }}>
        Total Tax: <span>₹{totalTaxAmountSuccess.toFixed(2)}</span>
      </Typography>
    </Box>
  );

  const getAllRewardsInfo = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      return;
    }
    try {
      const response = await axios.get(`${API.BASE_URL}level/getAllRewards`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (orderDetailData?.levelUpgradedTo !== null) {
        const filteredReward = response?.data?.data?.allLevelRewards?.filter(
          (item) => item?._id === orderDetailData?.levelUpgradedTo?.levelId
        );
        setAllRewards(filteredReward);
      }
    } catch (error) {
      console.log('Error in fetching rewards:', error);
    }
  };
  
  // const getAllRewardsInfo = async () => {
  //   try {
  //     const response = await axios.get(`${API.BASE_URL}level/getAllRewards`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`
  //         }
  //       }
  //     )
  //     if (orderDetailData?.levelUpgradedTo !== null) {
  //       const FilteredReward = response?.data?.data?.allLevelRewards?.filter(item => item?._id === orderDetailData?.levelUpgradedTo?.levelId)
  //       setAllRewards(FilteredReward);
  //     }
  //   } catch (error) {
  //     console.log(error, 'error in order Sucess');
  //   }
  // }

  useEffect(() => {
    getAllRewardsInfo();
  }, [orderDetailData]);

  const calculateTotalPrice = (item) => {
    let totalPrice = item?.product?.offerPrice * item?.quantity;
    item?.addons?.forEach(addon => {
      totalPrice += addon?.offerPrice * item?.quantity;
    });
    return totalPrice?.toFixed(2).replace(/\.?0*$/, '');
  };

  // const updateOrderStatus = async () => {
  //   try {
  //     const response = await axios.get(`${API.BASE_URL}order/getConsumerOrders/${outletData._id}?_id=${orderDetailData?.order?._id}`,
  //     {
  //       headers: {
  //         'Authorization': `Bearer ${token}`
  //       }
  //     });
  //     setOrderDetailData(response?.data?.data?.orders[0]);
  //   } catch (error) {
  //     console.error('Failed to fetch order:', error);
  //   }
  // };

  const updateOrderStatus = async () => {
    const token = localStorage.getItem("token");
    if (!token || !outletData?._id || !orderDetailData?.order?._id) {
      // console.log('Missing required data: API call not made.');
      return;
    }
    try {
      const response = await axios.get(
        `${API.BASE_URL}order/getConsumerOrders/${outletData._id}?_id=${orderDetailData.order._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setOrderDetailData(response?.data?.data?.orders[0]);
    } catch (error) {
      console.error('Failed to fetch order:', error);
    }
  };  

  useEffect(() => {
    const intervalId = setInterval(updateOrderStatus, 3000);
    return () => clearInterval(intervalId);
  }, [orderDetailData?.order?._id]);

  return (
    <>
      <Hidden mdUp>
        {playConfetti && (
          <Player
            autoplay
            loop={false}
            src="https://lottie.host/aec009ac-9eae-49c8-a521-5462a46f787b/dhJlEWsRLA.json"
            className="confetti"
            style={{ position: 'absolute' }}
          />
        )}
      </Hidden>
      <Box sx={{ backgroundColor: '#F9F0E7'}}>
        {!token ? (
          <Hidden mdUp>
            <Box sx={{ minHeight: '100vh'}}>
              <Typography>Please log in to view your cart.</Typography>
            </Box>
          </Hidden>
        ) : (
          <>
          {data?.status ? (
            <>
            <Hidden smUp>
              <PickUpCartComponent />
            </Hidden>
            <Box className="cartScreen">
              <Hidden smDown><Typography className="desktopCartTitle">Cart</Typography></Hidden>
              <Paper className="cartBox">
                {data?.data?.cartItems?.map((item, index) => (
                  <Box sx={{
                    borderBottom: index !== data.data.cartItems.length - 1 ? '0.5px dashed #BFBFBF' : 'none'
                  }}>
                  {/* <CartItem key={item._id} item={item} grandTotal={data.data.grandTotal} onItemUpdated={fetchData} /> */}
                    <CartItem key={item._id} item={item} grandTotal={data.data.grandTotal} />
                  </Box>
                ))}
                {/* <Hidden smUp>
                  <Box className="addMoreBox" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography onClick={() => navigate('/productsListing')} className="title">Add More Items</Typography>
                    <AddCircleIcon className="addIcon" />
                  </Box>
                </Hidden> */}
                <Hidden mdUp>
                  <Box onClick={() => navigate('/productsListing')} className="addMoreBox" sx={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography className="title">Add More Items</Typography>
                    <AddCircleOutline className="addIcon" />
                  </Box>
                </Hidden>
              </Paper>
              <Hidden mdUp>
                <PerfectCombo />
              </Hidden>
              <CouponsComponent cartAmount={data?.data?.cart?.grandTotal} couponDiscount={data?.data?.cart?.totalDiscount} totalCouponDiscount={totalCouponDiscount} totalSizeUpgradeAmount={totalSizeUpgradeAmount} couponData={couponData} refreshCartData={refreshCartData} />
              {selectedOrderType === 'PICKUP' ? (
                <PickUpTime key={pickUpTimeKey} onTimeSelected={handleRefreshPickTime} />
              ) 
              : selectedOrderType === 'DELIVERY' ? (
                <>
                  {/* <PickUpTime /> */}
                  <Box sx={{ display: 'none' }}>
                    <PickUpTime key={pickUpTimeKey} onTimeSelected={handleRefreshPickTime} />
                  </Box>
                  <DeliveryAddressComponent addressUpdated={addressUpdated} />
                </>
              ) : null}
              {activePickTime && (
                <Box className="orderSummaryTable">
                  <Box className="orderSummaryBox">
                    <Typography className="head">Order Summary</Typography>
                    <Box className="row">
                      <Typography className="itemHead">Item(s)</Typography>
                      <Typography className="itemValue">{totalQuantity}</Typography>
                    </Box>
                    <Box className="row">
                      <Typography className="itemHead">Taxable</Typography>
                      <Typography className="itemValue"><span style={{ fontSize: '14px', textDecoration: 'line-through', color: 'gray' }}>₹{totalMrp?.toFixed(2)}</span> ₹{totalOfferPrice?.toFixed(2)} </Typography>
                    </Box>
                    <Box className="row">
                      <Typography className="itemHead" sx={{ display: 'flex', alignItems: 'center'}}>Tax
                        <ClickAwayListener ClickAwayListener onClickAway={handleTaxTooltipClose}>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={handleTaxTooltipClose}
                            open={openTaxTooltip}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={taxTooltipContent}
                          >
                            <IconButton onClick={handleTaxTooltipOpen} size="small" sx={{ padding: 0, marginLeft: '5px' }}>
                              <InfoOutlined sx={{ fontSize: '12px' }} />
                            </IconButton>
                          </Tooltip>
                        </ClickAwayListener>
                      </Typography>
                      <Typography className="itemValue">₹{totalTaxAmount?.toFixed(2)}</Typography>
                    </Box>
                    {selectedOrderType === 'DELIVERY' && (
                      <Box className="row">
                        <Typography className="itemHead">Packaging Charges</Typography>
                        <Typography className="itemValue">₹ {data?.data?.additionalCharges?.packagingCharges}</Typography>
                      </Box>
                    )}
                    <Box className="row">
                      <Typography className="itemHead">Platform Fees</Typography>
                      <Typography className="itemValue">₹ {data?.data?.additionalCharges?.platformFees}</Typography>
                    </Box>
                    <Box className="row">
                      <Typography className="itemHead">Order Total</Typography>
                      <Typography className="itemValue">₹{formattedSum}</Typography>
                    </Box>
                    {/* <Box className="row">
                      <Typography className="itemHead">Offer Discount</Typography>
                      <Typography className="itemValue" sx={{ color: 'green' }}>- ₹{totalSavings?.toFixed(2)}</Typography>
                    </Box> */}
                    {selectedOrderType === 'DELIVERY' && (
                      <Box className="row">
                        <Typography className="itemHead">Shipping</Typography>
                        <Typography className="itemValue">{data?.data?.cart?.deliveryCharges === 0 ? "Free" : `₹ ${data?.data?.cart?.deliveryCharges}`}</Typography>
                      </Box>
                    )}
                    {totalCouponDiscount > 0 && (
                      <Box className="row">
                        <Typography className="itemHead">Coupon Discount</Typography>
                        <Typography className="itemValue" sx={{ color: 'green' }}>- ₹{totalCouponDiscount?.toFixed(2)}</Typography>
                      </Box>
                    )}
                    {/* {totalSizeUpgradeAmount > 0 &&
                      <Box className="row">
                        <Typography className="itemHead">Coupon Discount</Typography>
                        <Typography className="itemValue" sx={{ color: 'green' }}>- ₹{totalSizeUpgradeAmount?.toFixed(2)}</Typography>
                      </Box>
                    } */}
                    {data?.data?.cart?.coupon && totalSizeUpgradeAmount <= 0 && ( <Typography className="itemHead" sx={{ paddingBottom: '4px', color: '#1E9CED'}}>( {data?.data?.cart?.coupon?.couponCode} )</Typography>)}
                    <Box className="row" sx={{ borderTop: '1px dashed #707070', borderBottom: '1px solid #707070'}}>
                      <Typography className="itemHead" sx={{ fontWeight: '600'}}>Total Payable</Typography>
                      <Typography className="itemValue" sx={{ fontWeight: '600'}}>
                        ₹ {(
                          (data?.data?.cart?.grandTotal || 0) + 
                          (data?.data?.cart?.deliveryCharges === 0 ? 0 : data?.data?.cart?.deliveryCharges || 0)
                        ).toFixed(2)}
                      </Typography>
                      {/* <Typography className="itemValue" sx={{ fontWeight: '600'}}>₹ {data?.data?.cart?.grandTotal?.toFixed(2)}</Typography> */}
                    </Box>
                    <Box className="savedBox">
                      <Typography>You Saved</Typography>
                      <Typography>₹ {((parseFloat(totalSavings || 0) + parseFloat(totalSizeUpgradeAmount || 0) + parseFloat(totalCouponDiscount || 0)).toFixed(2))}</Typography>
                      {/* <Typography>₹ {data?.order?.totalDiscount}</Typography> */}
                    </Box>
                    <Box sx={{ padding: '7px 0 5px 0'}}>
                      <Typography sx={{ paddingBottom: '5px', fontSize: '12px' }}>Pay using</Typography>
                      <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                        <img style={{ height: '20px' }} src={Images.gPayLogo} alt='gPayLogo' />
                        <img style={{ height: '25px' }} src={Images.phonepeLogo} alt='phonepeLogo' />
                        <img src={Images.upiLogo} alt='upiLogo' />
                      </Box>
                    </Box>
                    {selectedOrderType === 'PICKUP' ? (
                      <Box onClick={isAnyProductDeletedOrExhausted ? undefined : handlePayment} className={`button ${isAnyProductDeletedOrExhausted ? 'cartDisabledBtn' : ''}`}>
                        Pay ₹ {( (data?.data?.cart?.grandTotal || 0) + (data?.data?.cart?.deliveryCharges === 0 ? 0 : data?.data?.cart?.deliveryCharges || 0)).toFixed(2)}
                      </Box>
                    ) 
                    : selectedOrderType === 'DELIVERY' ? (
                      <>
                        {selectedDeliveryAddressType === 'EXISTING' ? (
                          <Box onClick={isAnyProductDeletedOrExhausted ? undefined : handlePayment} className={`button ${isAnyProductDeletedOrExhausted ? 'cartDisabledBtn' : ''}`}>
                            Pay ₹ {( (data?.data?.cart?.grandTotal || 0) + (data?.data?.additionalCharges?.platformFees === 0 ? 0 : data?.data?.additionalCharges?.platformFees || 0) + (data?.data?.cart?.deliveryCharges === 0 ? 0 : data?.data?.cart?.deliveryCharges || 0)).toFixed(2)}
                          </Box>
                        ) : (
                          <Box onClick={handleAddAddressModalOpen} className="button">
                            <Typography variant='body1'>Proceed to add address</Typography>
                          </Box>
                        )}
                      </>
                    ) : null}
                  </Box>
                </Box>
              )}
            </Box>
            </>
          ) : (
            <Hidden mdUp>
              <Box className="cartScreen">
                <Box>
                  <Player
                    autoplay
                    loop={true}
                    src={Images.emptyCart}
                    className="emptyCart"
                  />
                </Box>
              </Box>
            </Hidden>
          )}
          </>
        )}
      </Box>
      <Dialog maxWidth open={openPayment} onClose={handleClosePayment} >
        <Box className="paymentModal">
          <Grid container>
            <Grid item md={6}>
              <Box>
                <Typography className="title">Select Payment Method</Typography>
                <Box className="paymentMethods">
                  <Box
                    className={`typeBox ${selectedPaymentMethod === 'UPI' ? 'selected' : ''}`}
                    onClick={() => handleAccordionChange('UPI')}
                  >
                    <Typography className="head">Pay using UPI ID</Typography>
                  </Box>
                  <Box
                    className={`typeBox ${selectedPaymentMethod === 'Card' ? 'selected' : ''}`}
                    onClick={() => handleAccordionChange('Card')}
                  >
                    <Typography className="head">Pay using credit or debit card</Typography>
                  </Box>
                  <Box
                    className={`typeBox ${selectedPaymentMethod === 'NetBanking' ? 'selected' : ''}`}
                    onClick={() => handleAccordionChange('NetBanking')}
                  >
                    <Typography className="head">Pay using Net Banking</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box className="secondColumn">
                <Box className="addressBox">
                  <Box className="firstBox">
                    <Box className="locationIconBox" sx={{ display: 'flex', alignItems: 'center' }}>
                      <FmdGood className="icon" /> 
                      <Typography className="text">{selectedOrderType === 'PICKUP' ? 'Pick Up' : 'Delivery'}</Typography>
                    </Box>
                    <Typography className="time">{activePickTime} {selectedOrderType === 'PICKUP' ? 'PickUp Time' : 'Delivery Time'}</Typography>
                  </Box>
                  <Typography className="secondBox">{store?.city}, {store?.address}</Typography>
                </Box>
                <Box className="cartTitle">
                  <Typography className="cart">Cart</Typography>
                  <Typography className="items">{totalQuantity} Items</Typography>
                </Box>
                <Paper className="cartBox">
                  {data?.data?.cartItems?.map((item, index) => (
                    <Box className="itemBox" key={item._id} style={{ borderBottom: index !== data.data.cartItems.length - 1 ? '1px dashed #D6D6D6' : 'none' }}>
                      <Typography className="productName">{item?.product?.name}</Typography>
                      <Box sx={{ display: 'flex' }}>
                        <Typography className="price">₹{calculateTotalPrice(item)} </Typography> 
                        <Typography className="quantity">({item?.quantity})</Typography>
                      </Box>
                    </Box>
                  ))}
                </Paper>
                <Box
                  className="button"
                  onClick={handlePaymentType}
                  disabled={paymentButtonDisabled}
                  style={{ opacity: paymentButtonDisabled ? 0.5 : 1 }}
                >
                  {paymentButtonDisabled ? "Please select a payment method" : `Pay ₹ ${((data?.data?.cart?.grandTotal || 0) + (data?.data?.cart?.deliveryCharges === 0 ? 0 : data?.data?.cart?.deliveryCharges || 0)).toFixed(2)}`}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
      <Dialog maxWidth className="orderDetailsModal" open={orderDetailsModal} onClose={handleCloseOrderDetailsModal}>
        <Box className="orderDetails">
          <Grid container>
            {orderDetailData?.order?.ordersType === "DELIVERY" ? (
              <Grid item md={4}>
                <LoadScript googleMapsApiKey={GOOGLE_MAP.GOOGLE_MAP_KEY}>
                  <Box sx={{ backgroundColor: '#fff', color: '#186B8C', fontWeight: '600', padding: '8px 20px', borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px', width: '120px', display: 'flex', justifyContent: 'center', margin: '0 auto -10px auto' }}>
                    Order Id: #{orderDetailData?.order?.order_sequence}{orderDetailData?.order?.order_no}
                  </Box>
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                    center={currentCoordinates}
                    options={{ mapTypeControl: false, zoomControl: false, fullscreenControl: false, keyboardShortcuts: false }}
                  >
                    <MarkerF
                      position={currentCoordinates}
                      title="Your Location"
                    />
                    <MarkerF
                      position={outlet}
                      icon={outletMarker}
                      title="Outlet"
                    />
                  </GoogleMap>
                </LoadScript>
                <Box className="orderDeliveryDetail">
                    <Box sx={{ display: 'flex' }}>
                        <img className="successGifDelivery" src={Images.successTick} alt='successTick' />
                        <Typography className="titleDelivery">Your order has been placed successfully!</Typography>
                    </Box>
                    <Box sx={{ padding: '0 12px 0px 12px'}}>
                        <Box sx={{ display: 'flex' }}>
                          <Typography variant='body2' sx={{ fontWeight: '600' }}>Delivering to :</Typography>
                          {orderDetailData?.order?.deliveryDetails.type === 'HOME' && <HomeRounded sx={{ color: '#1E9CED' }} />}
                          {orderDetailData?.order?.deliveryDetails.type === 'WORK' && <WorkRounded sx={{ color: '#1E9CED' }} />}
                          {orderDetailData?.order?.deliveryDetails.type !== 'HOME' && orderDetailData?.order?.deliveryDetails.type !== 'WORK' && <LocationOnOutlined sx={{ color: '#1E9CED' }} />}
                          <Typography variant='body2' sx={{ fontWeight: '600' }}>{orderDetailData?.order?.deliveryDetails.type}</Typography>
                        </Box>
                        <Typography variant='body2' >{orderDetailData?.order?.deliveryDetails.recieverName} {orderDetailData?.order?.deliveryDetails.addressLine1}, {orderDetailData?.order?.deliveryDetails.addressLine2}, {orderDetailData?.order?.deliveryDetails.city}, {orderDetailData?.order?.deliveryDetails.state}, {orderDetailData?.order?.deliveryDetails.pincode}</Typography>
                    </Box>
                    <img className="couponBorderInverted" src={Images.couponBorderInverted} />
                </Box>
              </Grid>
            ) : (
              <Grid item md={4}>
                  <Player autoplay loop={false} keepLastFrame={true} src={Images.successGif} className="successGif"></Player>
                  <Box className="qrBox">
                    <Typography className="orderNo">#{orderDetailData?.order?.order_sequence}{orderDetailData?.order?.order_no}</Typography>
                    <QRCodeSVG className="qrCode" value={orderDetailData?.order?.scanOrderId} />
                    <Typography className="scanText">Please Scan The QR Code At Store</Typography>
                  </Box>
              </Grid>
            )}
            <Grid item md={8}>
              <Grid container>
                <Grid item md={12} sx={{ display: 'flex', alignItems: 'flex-end', textAlign: 'right', justifyContent: 'center' }}>
                  {showBox ? (
                    <Box sx={{ backgroundColor: '#71BAE6', padding: '10px', color: '#fff', fontSize: '16px', textAlign: 'center', display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginTop: '8px' }}>
                      Cancel your order within {countdown}s
                      <Button 
                        onClick={handleCancelOrder} 
                        sx={{ color: 'white', backgroundColor: 'red' }} 
                      >
                        <Typography variant='caption'>Cancel Order</Typography>
                      </Button>
                    </Box>
                  ) : (
                    // <Typography className="placedTitle">Your Order Has Been {orderText} Successfully!</Typography>
                    <Typography className="placedTitle">Your Order Status: {orderDetailData?.order?.orderStatus}</Typography>
                  )}
                </Grid>
                <Grid item md={6}>
                  <Box className="marginTop orderFinalSummary">
                    <img className="couponBorder" src={Images.couponBorder} />
                    <Box className="orderItemsList" style={{ overflow: 'auto'  }}>
                      <Typography sx={{ textAlign: 'center', color: '#186B8C', fontWeight: '600' }}>Item Summary</Typography>
                      {orderDetailData?.orderItems?.map((item, index) => (
                        <Grid key={item?._id} container className="item" sx={{ borderBottom: index === orderDetailData.orderItems.length - 1 ? 'none' : '1px solid #C5C5C5' }}>
                          <Grid item className="detail-grid" xs={6}>
                              <Typography className="item-name">{item?.product?.name}</Typography>
                          </Grid>
                          <Grid item className="detail-grid" xs={3} sx={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
                            <Typography className="item-name">Qty - {item?.quantity}</Typography>
                          </Grid>
                          <Grid item className="detail-grid" xs={3} sx={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
                              <Typography className="item-cost">₹{calculateTotalPrice(item)}</Typography>
                          </Grid>
                          <Grid item md={12}>
                            {item?.addons?.length > 0 && (
                              <Stack flexDirection={'row'}>
                                {item.addons.map((addon, index) => (
                                  <Typography key={index} sx={{ paddingRight: '4px' }} className="item-attribute">
                                    {addon.selectedValue}{index === item.addons.length - 1 ? '.' : ', '} 
                                  </Typography>
                                ))}
                              </Stack>
                            )}
                          </Grid>
                        </Grid>
                      ))}
                    </Box>
                  </Box>
                  <Box className="addressBox">
                    <Box className="firstBox">
                      <Box className="locationIconBox" sx={{ display: 'flex', alignItems: 'center' }}>
                      <FmdGood className="icon" /> 
                      {orderDetailData?.order?.ordersType !== "DELIVERY" ? (
                        <Typography className="text">Pick Up</Typography>
                      ) : (
                        <Typography className="text">From Outlet</Typography>
                      )}
                      </Box>
                      {orderDetailData?.order?.ordersType !== "DELIVERY" ? ( <Typography className="time">{orderDetailData?.order?.readyTime} Pick Up</Typography> ) : ( <Typography className="time">{orderDetailData?.order?.readyTime} Delivery time</Typography> )}
                    </Box>
                    <Typography className="secondBox">{orderDetailData?.order?.outlet?.name}, {orderDetailData?.order?.outlet?.city}, {orderDetailData?.order?.outlet?.state}</Typography>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box className="orderFinalSummary">
                    <Box className="orderSummaryTable">
                      <Typography className="head">Order Summary</Typography>
                      <Box className="row">
                          <Typography className="itemHead">Item(s)</Typography>
                          <Typography className="itemValue">{totalQuantityInOrder}</Typography>
                      </Box>
                      <Box className="row">
                        <Typography className="itemHead">Order Total</Typography>
                        <Typography className="itemValue"><span style={{ fontSize: '14px', textDecoration: 'line-through', color: 'gray' }}>₹{totalMrpOrder}</span> ₹{totalOfferPriceOrder}</Typography>
                      </Box>  
                      <Box className="row">
                        <Typography className="itemHead" sx={{ display: 'flex', alignItems: 'center'}}>Tax
                        <ClickAwayListener onClickAway={() => setSuccessTaxOpenTooltip(false)}>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={() => setSuccessTaxOpenTooltip(false)}
                            open={openSuccessTaxTooltip}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={successTaxTooltipContent}
                          >
                            <IconButton onClick={() => setSuccessTaxOpenTooltip(true)} size="small" sx={{ padding: 0, marginLeft: '5px' }}>
                              <InfoOutlined sx={{ fontSize: '12px' }} />
                            </IconButton>
                          </Tooltip>
                        </ClickAwayListener>
                        </Typography>
                        <Typography className="itemValue">₹{totalTaxAmountSuccess?.toFixed(2)}</Typography>
                      </Box>
                      {orderDetailData?.order?.platformFees > 0 && (
                        <Box className="row">
                          <Typography className="itemHead">Platform Fees</Typography>
                          <Typography className="itemValue">
                            {orderDetailData.order.platformFees.toFixed(2) === "0.00" ? "Free" : `₹ ${orderDetailData.order.platformFees.toFixed(2)}`}
                          </Typography>
                        </Box>
                      )}
                      {orderDetailData?.order?.ordersType === 'DELIVERY' && (
                        <>
                        <Box className="row">
                          <Typography className="itemHead">Packaging Charges</Typography>
                          <Typography className="itemValue">{orderDetailData?.order?.packagingCharges?.toFixed(2) === 0 ? "Free" : `₹ ${orderDetailData?.order?.packagingCharges?.toFixed(2)}`}</Typography>
                        </Box>
                        <Box className="row">
                          <Typography className="itemHead">Shipping</Typography>
                          <Typography className="itemValue">{orderDetailData?.order?.deliveryCharges?.toFixed(2) === 0 ? "Free" : `₹ ${orderDetailData?.order?.deliveryCharges?.toFixed(2)}`}</Typography>
                        </Box>
                        </>
                      )}
                      {totalCouponDiscount > 0 && (
                        <Box className="row">
                          <Typography className="itemHead">Coupon Discount</Typography>
                          <Typography className="itemValue" sx={{ color: 'green' }}>- ₹{totalCouponDiscount?.toFixed(2)}</Typography>
                        </Box>
                      )}
                      {orderDetailData?.order?.coupon && totalSizeUpgradeAmount <= 0 && ( <Typography className="itemHead" sx={{ paddingBottom: '4px', color: '#1E9CED'}}>( {orderDetailData?.order?.coupon?.couponCode} )</Typography>)}
                      {/* <Box className="row">
                        <Typography className="itemHead">Platform Fees</Typography>
                        <Typography className="itemValue">{orderDetailData?.order?.platformFees?.toFixed(2) === 0 ? "Free" : `₹ ${orderDetailData?.order?.platformFees?.toFixed(2)}`}</Typography>
                      </Box> */}
                      <Box className="row" sx={{ borderTop: '1px dashed #707070', borderBottom: '1px solid #707070'}}>
                          <Typography className="itemHead" sx={{ fontWeight: '600'}}>Total Payable</Typography>
                          <Typography className="itemValue" sx={{ fontWeight: '600'}}>₹ {orderDetailData?.order?.grandTotal?.toFixed(2)}</Typography>
                      </Box>
                    </Box>
                    <Box className="savedBox">
                        <Typography className="text">You Saved</Typography>
                        <Typography>₹ {((parseFloat(totalSavings || 0) + parseFloat(orderDetailData?.order?.totalDiscount || 0)).toFixed(2))}</Typography>
                    </Box>
                    <Box className="paymentBox">
                        <Typography className="head">Payment Details</Typography>
                        <Box className="payDetails">
                            <Typography className="payDetails">Payment mode: {orderDetailData?.order?.paymentMode}</Typography>
                            <Typography className="payDetails">Date: {formatDate(orderDetailData?.order?.createdAt)}</Typography>
                        </Box>
                        <Typography onClick={handleCloseOrderDetailsModal} className="button">Continue Shopping</Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
      <Dialog open={addAddressModal} onClose={handleAddAddressModalClose} sx={{ '& .MuiPaper-root': {  borderRadius: '16px' },}}>
        <Box className="addressModal">
          <Grid container className="address-container">
            <Grid item xs={9}>
              <Typography className="address">
                <MyLocation sx={{ paddingRight: '5px' }} />
                  {address?.address?.suburb || address?.address?.village || address?.address?.town || address?.address?.county}
              </Typography>
              <Typography className="address2">
                {address?.address?.state_district}, {address?.address?.state}, {address?.address?.postcode}.
              </Typography>
            </Grid>
          </Grid>
          <Box>
            <Typography className="enterDetailsTitle">Enter Address Details</Typography>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => (
                <Form className="formBox">
                  <TextField
                    name="flat"
                    className="textField"
                    fullWidth
                    placeholder="Enter"
                    label="Flat / H. No. / Floor No. / Building Name"
                    value={values.flat}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.flat && touched.flat && <div className="errorText">{errors.flat}</div>}
                  <TextField
                    name="street"
                    className="textField"
                    fullWidth
                    placeholder="Enter"
                    label="Street Address"
                    value={values.street}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.street && touched.street && <div className="errorText">{errors.street}</div>}
                  <TextField
                    name="landmark"
                    className="textField"
                    fullWidth
                    placeholder="Enter"
                    label="Landmark (Optional)"
                    value={values.landmark}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <TextField
                    name="receiverName"
                    className="textField"
                    fullWidth
                    placeholder="Enter"
                    label="Receiver's Name"
                    value={values.receiverName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.receiverName && touched.receiverName && <div className="errorText">{errors.receiverName}</div>}
                  <TextField
                    name="receiverContact"
                    className="textField"
                    fullWidth
                    placeholder="Enter"
                    label="Receiver's Contact"
                    value={values.receiverContact}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.receiverContact && touched.receiverContact && <div className="errorText">{errors.receiverContact}</div>}
                  <Grid container justifyContent="space-around">
                    <Grid item xs={3}>
                      <Box
                        className={`addressChip ${activeChip === 'HOME' ? 'selected' : ''}`}
                        onClick={() => handleChipClick('HOME')}
                      >
                        <Home sx={{ paddingRight: '4px' }}/> Home
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box
                        className={`addressChip ${activeChip === 'WORK' ? 'selected' : ''}`}
                        onClick={() => handleChipClick('WORK')}
                      >
                        <WorkRounded sx={{ paddingRight: '4px' }}/> Work
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box
                        className={`addressChip ${activeChip === 'OTHER' ? 'selected' : ''}`}
                        onClick={() => handleChipClick('OTHER')}
                      >
                        <LocationOnOutlined sx={{ paddingRight: '4px' }}/> Other
                      </Box>
                    </Grid>
                  </Grid>
                  {showAddressNameField && (
                    <>
                    <TextField
                      name="addressName"
                      className="textField"
                      fullWidth
                      placeholder="Enter"
                      label="Address Name"
                      value={values.addressName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.addressName && touched.addressName && <div className="errorText">{errors.addressName}</div>}
                    </>
                  )}
                  <Button type="submit" className="button" disabled={isSubmitting}>
                    {isSubmitting ? 'Submitting...' : 'Continue'}
                  </Button>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Dialog>
      <Dialog open={orderCancelModal} onClose={() => { setOrderCancelModal(false); window.location.reload(); }} sx={{ '& .MuiPaper-root': {  borderRadius: '16px', overflow: 'hidden', },}}>
        <Box className="addressModal">
          <Grid container className="address-container" sx={{ display: 'flex', justifyContent: 'center'}}>
            <img src={Images.successTick} />
            <h2>Your order was cancelled</h2>
            <Button onClick={() => { setOrderCancelModal(false); window.location.reload(); }}>Continue Shopping</Button>
          </Grid>
        </Box>
      </Dialog>
      <Dialog open={levelUpgradeModal} onClose={() => { setLevelUpgradeModal(false); window.location.reload(); }} sx={{ '& .MuiPaper-root': {  borderRadius: '16px', overflow: 'hidden', },}}>
        <DialogContent sx={{ bgcolor: '#fff', overflow: 'auto', borderRadius: 2 }}>
          <div style={{ textAlign: 'center', padding: 2 }}>
            <img src={Images.successTick} />
            {allRewards?.length > 0 && (
              <Typography variant="h6" style={{ fontWeight: 'bold', color: 'primary.contrastText' }}>
                You have Reached {allRewards[0].title}
              </Typography>
            )}
          </div>
          {allRewards?.map((rewardsValues, index) => (
            <div key={index} style={{ marginTop: 2 }}>
              <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                {rewardsValues.title} Benefits
              </Typography>
              {rewardsValues.levelRewards?.map((level, index) => (
                <div key={index} style={{ display: 'flex', flexDirection: 'row', marginTop: 1 }}>
                  <Typography variant="body2" style={{ marginRight: 1 }}>*</Typography>
                  <div>
                    <Typography variant="body2">{level.title}</Typography>
                    <Typography variant="body2" style={{ color: 'grey.500' }}>
                      {level.description}
                    </Typography>
                  </div>
                </div>
              ))}
            </div>
          ))}
          <Button onClick={() => { setLevelUpgradeModal(false); window.location.reload(); }}>Close</Button>
        </DialogContent>
      </Dialog>
      <Dialog open={couponSavedModal} onClose={() => { setCouponSaved(false); }} sx={{ '& .MuiPaper-root': {  borderRadius: '16px', overflow: 'hidden', },}}>
        <DialogContent sx={{ bgcolor: '#fff', overflow: 'auto', borderRadius: 2 }}>
          <div style={{ textAlign: 'center', padding: 2 }}>
            <img src={Images.successTick} />
          </div>
          {totalCouponDiscount > 0 && (
            <Box className="row">
              <Typography className="itemHead">Coupon Discount</Typography>
              <Typography className="itemValue" sx={{ color: 'green' }}>- ₹{totalCouponDiscount?.toFixed(2)}</Typography>
            </Box>
          )}
          {totalSizeUpgradeAmount > 0 &&
            <Box className="row">
              <Typography className="itemHead">Coupon Discount</Typography>
              <Typography className="itemValue" sx={{ color: 'green' }}>- ₹{totalSizeUpgradeAmount?.toFixed(2)}</Typography>
            </Box>
          }
          <Button onClick={() => { setCouponSaved(false) }}>Close</Button>
        </DialogContent>
      </Dialog>
      <Dialog open={paymentProccessingLoading} PaperProps={{ style: { backgroundColor: 'transparent', boxShadow: 'none',}, }} BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.3)', }, }}>
        <DialogContent sx={{ overflow: 'auto', borderRadius: 2 }}>
          <img src={Images.paymentLoader} alt="Loading..." style={{ width: '300px' }} />
          <Typography variant="h6" sx={{ marginTop: '-30px', color: '#fff'}}>Processing payment, please wait...</Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CartScreen;
