export const API = {
    // BASE_URL: 'https://xpanse.dev.bexcart.com/',
    BASE_URL: 'https://api.xpansecafe.com/',
}

export const RAZOR_PAY = {
    // RAZOR_PAY_KEY: 'rzp_live_bwgMpxKo8tHR7s',
    RAZOR_PAY_KEY: 'rzp_live_F5u8OhE3jITTVm',
    // RAZOR_PAY_KEY: 'rzp_test_oni9zmJuHiNOyq',
}

export const GOOGLE_MAP = {
    GOOGLE_MAP_KEY: 'AIzaSyAkMTmrfVp5O6MFzLdcLACGt1FKCy2PVkY',
}
