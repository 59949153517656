import React, { useState, useEffect, useRef} from 'react';
import { Box, Grid, Stack, Typography, Dialog, Drawer, Button, Hidden, useTheme, useMediaQuery, IconButton, CircularProgress } from '@mui/material';
import Images from '../../utils/Images';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '../../store/Products/productsSlice';
import axios from 'axios';
import { API } from '../../utils/Api';
import OrderAgain from '../../components/OrderAgain';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { SearchBar } from '../../components/SearchBar';
import StoreHeader from '../../components/StoreHeader';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { setData } from '../../store/Cart/cartSlice';
import FavouriteProducts from '../../components/FavouriteProducts';
import { CloseOutlined } from '@mui/icons-material';
import AddonIconComponent from '../../components/AddonIconComponent';
import ArrivingNowOrders from '../../components/ArrivingNowOrders';
import ProductListingSkeleton from '../../components/SkeletonComponents/ProductListingSkeleton';
import AuthModals from '../../components/AuthComponents/AuthModals';
import ProductDrawer from '../../components/ProductDrawer';
import { Player } from '@lottiefiles/react-lottie-player';

const ProductsListing = () => {
  const [showBox, setShowBox] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const dispatch = useDispatch();
  // const products = useSelector((state) => state.products.products);
  // const categories = useSelector((state) => state.products.categories);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  // const status = useSelector((state) => state.products.status);
  // const error = useSelector((state) => state.products.error);
  const [activeCategory, setActiveCategory] = useState(categories[0]?._id);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const outlet = localStorage.getItem("selectedStoreData");
  const deliveryType = localStorage.getItem("selectedOrderType");
  const token = localStorage.getItem("token");
  const outletObject = JSON.parse(outlet);
  const outletId = outletObject?._id;
  const [isFavorited, setIsFavorited] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [cartData, setCartData] = useState([]);
  // const [searchText, setSearchText] = useState('');
  const navigate = useNavigate();
  // const data = useSelector(state => state.cart.data);
  // const [data, setData] = useState(null);
  // const { searchText } = useSearch();
  const [wishlistData, setWishlistData] = useState([]);
  // const cartDataRedux = useSelector((state) => state.setData);
  const cartDataRedux = useSelector(state => state?.cart?.data);
  const searchText = useSelector(state => state.search.searchText);
  const [outletData, setOutletData] = useState(JSON.parse(localStorage.getItem('selectedStoreData')));
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const theme = useTheme();
  const matchesMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const [selectedAddons, setSelectedAddons] = useState({});
  const [addingToCart, setAddingToCart] = useState(false);
  const [fetchingProducts, setFetchingProducts] = useState(true);
  const matchesMobile = useMediaQuery(theme.breakpoints.up('md'));
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   if (status === 'idle') {
  //     dispatch(fetchCategories()).then((action) => {
  //       const fetchedCategories = action.payload;
  //       const initialCategoryId = fetchedCategories.length > 0 ? fetchedCategories[0]._id : null;
  //       setActiveCategory(initialCategoryId);
  //       dispatch(fetchProducts(initialCategoryId));
  //     });
  //   }
  // }, [status, dispatch]);

  const handleCategoryClick = (categoryId, index) => {
    setActiveCategory(categoryId);
    dispatch(fetchProducts(categoryId));
    console.log("Products after dispatching fetchProducts:", products);
    scrollToIndex(index);
  };

  useEffect(() => {
    fetchUserCartData();
    const shimmerTimeout = setTimeout(() => {
      setShowBox(true);
    }, 3000);
    return () => clearTimeout(shimmerTimeout);
  }, []);

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setIsFavorited(isProductInWishlist(product._id));
    console.log('Product', product);
  };

  const handleCloseModal = () => {
    setSelectedProduct(null);
    setQuantity(1);
    setSelectedAddons({});
  };
      
  const handleIncreaseQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const handleAddonSelect = (addonId, addonValue) => {
    setSelectedAddons((prevSelected) => ({
      ...prevSelected,
      [addonId]: addonValue,
    }));
    // console.log('Addons', { addonId, ...addonValue });
  };

  useEffect(() => {
    if (selectedProduct?.hasAddon && selectedProduct?.addons && selectedProduct?.addons?.length > 0) {
      const defaultAddons = {};
      selectedProduct?.addons?.forEach((addon) => {
        const defaultAddonValue = addon?.addonValues?.find((value) => value?.isDefault);
        if (defaultAddonValue) {
          defaultAddons[addon._id] = defaultAddonValue;
        }
      });
      setSelectedAddons(defaultAddons);
    }
  }, [selectedProduct]);

  const handleAddToCart = async () => {
    if (token) {
      setAddingToCart(true);
      try {
        const addonsArray = Object.keys(selectedAddons).map((addonId) => ({
          _id: addonId,
          selectedValue: selectedAddons[addonId].value,
          addonValueId: selectedAddons[addonId]._id,
        }));
        const cartItem = {
          ordersType: deliveryType,
          outlet: outletId,
          cartItems: [
            {
              product: selectedProduct._id,
              quantity: quantity,
              addons: addonsArray.length > 0 ? addonsArray : undefined,
            }
          ],
        };
        const response = await axios.post(`${API.BASE_URL}cart`, cartItem, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        handleCloseModal();
        fetchUserCartData();
        setAddingToCart(false);
        toast.success('Product added to cart!!!');
        console.log(response.data);
      } catch (error) {
        console.error(error);
      } finally {
        setAddingToCart(false);
      }
    } else {
      if (matchesMdUp) {
        // setopen(true);
        handleOpenAuth();
      } else {
        navigate('/mobileNumber');
      }
    }
  };

  const fetchUserCartData = async () => {
    if (!token || !outletData?._id) {
      return;
    }
    try {
      const response = await axios.get(`${API.BASE_URL}cart/userCart/${outletData._id}/${deliveryType}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(setData(response?.data));
      setCartData(cartDataRedux);
      // console.log('cartDataRedux', cartDataRedux);
      console.log('Cart Screen Data', response?.data?.data?.cartItems);
    } catch (error) {
      dispatch(setData([]));
      console.log('Cart Screen Error', error?.response?.data);
    }
  };

  const getCategoriesList = async () => {
    setFetchingProducts(true);
    if (!outletData?._id) {
      return;
    }
    try {
      const response = await axios.get(`${API.BASE_URL}masters/category/getCategoryWithProducts/${outletData._id}`,{
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          search: searchText,
        }
      }); 
      setCategories(response?.data?.data?.categories);
      setFetchingProducts(false);
      // setProducts(response.data.data.products);
      // console.log('Categories fetched successfully in the screen:', categories);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setFetchingProducts(false);
    }
  };  

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        getCategoriesList();
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    let timeoutId;
    const checkDataAvailability = () => {
      if (outletData) {
        setIsDataAvailable(true);
        clearTimeout(timeoutId);
        getCategoriesList();
      } else {
        timeoutId = setTimeout(checkDataAvailability, 500);
        setOutletData(JSON.parse(localStorage.getItem('selectedStoreData')));
      }
    };
    checkDataAvailability();
    return () => {
      clearTimeout(timeoutId);
    };
  }, [outletData]);

  const getWishlist = async () => {
    if (!token || !outletData?._id) {
      return;
    }
    try {
      const response = await axios.get(`${API.BASE_URL}wishlist/getUserWishlist/${outletData?._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setWishlistData(response?.data?.data?.wishList_product_Ids);
      // console.log('Wishlist in initial Screen Data', response?.data.data.wishList_product_Ids);
    } catch (error) {
      console.log('Wishlist Screen Error', error?.response?.data);
    }
  };

  const isProductInWishlist = (productId) => {
    return wishlistData.includes(productId);
  };

  const handleFavoriteClick = async () => {
    if (token) {
      try {
        if (isProductInWishlist(selectedProduct._id)) {
          const response = await axios.post(
            `${API.BASE_URL}wishlist/remove`, 
            { productId: selectedProduct._id },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setIsFavorited(false);
          toast.success('Product removed from favorites!!!');
          // console.log("Wishlist Remove Success", response.data);
          getCategoriesList();
          getWishlist();
        } else {
          const response = await axios.post(
            `${API.BASE_URL}wishlist/add`, 
            { productId: selectedProduct._id },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setIsFavorited(true);
          getCategoriesList();
          getWishlist();
          toast.success('Product added to favorites!!!');
          // console.log("Wishlist Add Success", response.data);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      if (matchesMobile) {
        // setopen(true);
        handleOpenAuth();
      } else {
        navigate('/mobileNumber');
      }
    }
  };

  useEffect(() => {
    // getProductsList(activeCategory);
    getCategoriesList();
  }, [searchText]);

  useEffect(() => {
    fetchUserCartData();
    getCategoriesList();
    getWishlist();
    // getProductsList(activeCategory);
  },[]);

  const getProductQuantityInCart = (productId) => {
      let totalQuantity = 0;
      cartDataRedux?.data?.cartItems?.forEach(item => { if (item.product._id === productId) { totalQuantity += item.quantity; }});
      return totalQuantity;
  };

  const containerRef = useRef(null);
  
  const scrollToIndex = (index) => {
    const container = containerRef.current;
    const element = container.childNodes[index];
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const getRandomRating = () => {
    return (Math.random() * (4.2 - 3.7) + 3.7).toFixed(1);
  };

  const [totalPrice, setTotalPrice] = useState(selectedProduct?.offerPrice || 0);
  const [totalMrpPrice, setTotalMrpPrice] = useState(selectedProduct?.mrp || 0);
  const [offerPercentage, setOfferPercentage] = useState(0);

  useEffect(() => {
    let calculateTotalPrice = () => {
      let price = selectedProduct?.offerPrice ?? 0;
      let mrp = selectedProduct?.mrp ?? 0;
      Object.values(selectedAddons).forEach(addon => {
        price += addon.offerPrice ?? 0;
        mrp += addon.mrp ?? 0;
      });
      setTotalPrice(price);
      setTotalMrpPrice(mrp);
    };
    calculateTotalPrice();
  }, [selectedAddons, selectedProduct]);

  useEffect(() => {
    if (totalMrpPrice > 0 && totalPrice < totalMrpPrice) {
      const discount = ((totalMrpPrice - totalPrice) / totalMrpPrice) * 100;
      setOfferPercentage(discount);
    } else {
      setOfferPercentage(0);
    }
  }, [totalPrice, totalMrpPrice]);

  const params = useParams();
  const referralId = params['referId'];

  useEffect(() => {
    if (referralId) {
      localStorage.setItem('referralId', referralId);
      console.log("Referral ID stored in localStorage:", referralId);
    }
  }, [referralId]); 

  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenAuth = () => {
    setModalOpen(true);
  }

  const handleCloseAuth = () => {
    setModalOpen(false);
  }

  return (
    <>
      <Hidden mdUp>
        <StoreHeader />
        <SearchBar />
        <ArrivingNowOrders />
        <Box className="imageCategories">
          <Box className="imageCategoryContainer">
            {categories?.slice().sort((a, b) => a.sequenceNumber - b.sequenceNumber).map((category, index) => (
              <Box className="img-grid" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Box key={category?._id} onClick={() => handleCategoryClick(category?._id, index)} className="imageBox">
                  <img className="image" src={category?.imageURL} alt="product" />
                </Box>
                <Typography className="categoryName">{category?.name}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
        {/* <OrderAgain /> */}
        {/* <FavouriteProducts /> */}
      </Hidden>
      <Hidden mdDown>
        <Box className="imageCategories">
          <Box className="imageCategoryContainer">
            {categories.map((category, index) => (
              <Box className="img-grid" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Box key={category._id} onClick={() => handleCategoryClick(category._id, index)} className="imageBox">
                  <img className="image" src={category.imageURL} alt="product" />
                </Box>
                <Typography className="categoryName">{category.name}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Hidden>
      <Box>
      {fetchingProducts ? (
        <ProductListingSkeleton />
      ) : categories?.length <= 0 ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
          <Player autoplay loop={true} keepLastFrame={true} src={Images.noResult} style={{ height: '350px'}} />
          <Typography variant='h6'>Sorry didn't find what you are looking for...</Typography>
        </Box>
      ) : (
        <React.Fragment>
          <Box className="products-listing">
            <Box className="category-list">
              {categories?.slice().sort((a, b) => a.sequenceNumber - b.sequenceNumber).map((category, index) => (
                <Box className="d-flex category-items-content" key={category._id}>
                  <Box className={`category-item-highlight-line ${activeCategory === category._id || (index === 0 && !activeCategory) ? 'active' : ''}`}></Box>
                  <Box
                    className={`category-item ${activeCategory === category._id || (index === 0 && !activeCategory) ? 'active' : ''}`}
                    onClick={() => handleCategoryClick(category._id, index)}
                  >
                    {category.name}
                  </Box>
                </Box>
              ))}
            </Box>
            <Box className="products-container">
              <Grid ref={containerRef} container>
                {categories.map((category, index) =>{
                let totalProductsRendered = 0;
                const totalProducts = category?.products?.length;
                let productsPerRow = 3;
                  return (
                    // <Grid  ref={el => categoryRefs.current[index] = el} container key={category._id}>
                    <Grid container key={category?._id}>
                      <Grid md={12}>
                        <img className="categoryTopImage" src={category?.bannerImageUrl} alt={category?.name} />
                      </Grid>
                      {category?.products.slice().sort((a, b) => a.sequenceNumber - b.sequenceNumber).map((product, productIndex) => {
                        const rowPosition = Math.floor(productIndex / productsPerRow);
                        const colPosition = productIndex % productsPerRow;
                        const isLastRow = (Math.ceil(totalProducts / productsPerRow) - 1) === rowPosition;
                        const isLastInRow = colPosition === (productsPerRow - 1) || productIndex === (totalProducts - 1);
                        const isFirstInRow = colPosition === 0;
                        return (
                          <>
                          <Grid item sm={12} md={4} key={product._id}>
                              <Box key={product?._id}
                                sx={{
                                  ...(matchesMdUp ? { borderRight: isLastInRow ? 'none' : '1px dashed #D6D6D6' } : { borderRight: 'none', borderLeft: 'none', }),
                                  borderBottom: isLastRow ? 'none' : '1px dashed #D6D6D6',
                                  paddingBottom: (index === categories.length - 1 && totalProductsRendered === category.products.length) ? '20px' : '35px',
                                  ...(matchesMdUp ? {} : {
                                    borderBottom: productIndex === category.products.length - 1 ? 'none' : '1px dashed #6a6a6a',
                                    borderImage: 'repeating-linear-gradient(90deg, #D6D6D6, #D6D6D6 10px, transparent 10px, transparent 14px) 2',
                                    paddingBottom: productIndex === category.products.length - 1 ? '20px' : '35px'
                                  })
                                }}  
                                className={`product-item ${product.isExhausted ? 'exhausted' : ''}`}
                              >
                                <Grid container sx={{ alignItems: 'center' }}>
                                  <Hidden smUp>
                                    <Grid item xs={3} sm={3} md={3} className="imageBox" onClick={() => handleProductClick(product)}>
                                      <img className="image" src={product?.displayImageUrl} alt="Product" />
                                    </Grid>
                                  </Hidden>
                                  <Grid item xs={9} sm={3} md={12} sx={{ margin: '0px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Hidden smUp>
                                      <Grid container className="typeAndFavouriteBox" onClick={() => handleProductClick(product)} direction={'row'}>
                                        <Grid item xs={12} sm={12} md={10} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0 0 0 0px' }}>
                                          <Grid container>
                                            <Grid item xs={11} sm={11}>
                                              <Typography className="productName">{product?.name}</Typography>
                                            </Grid>
                                            <Grid item xs={1} sm={1} sx={{ display: 'flex', alignItems: 'center'  }}>
                                              {isProductInWishlist(product._id) ? (
                                                <img className="favouriteMarked" src={Images.heartFilled} alt="heartIconFilled" />
                                              ) : (
                                                <img className="favouriteMarked" src={Images.heartIcon} alt="heartIconFilled" />
                                              )}
                                            </Grid>
                                          </Grid>
                                          <Stack className="productDetails" direction={'row'} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-around' }}>
                                            <Box sx={{ display: 'flex'}}>
                                              <Typography className="productPrice">₹{product?.offerPrice} <span className='productMrp'> ₹{product?.mrp}</span></Typography>
                                              <Typography className="productOff">{product?.offerPercentage?.toFixed(0)}% OFF</Typography>
                                            </Box>
                                          </Stack>
                                        </Grid>
                                      </Grid>
                                    </Hidden>
                                    <Hidden smUp>
                                      <Box item xs={12} sm={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Grid container>
                                          <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                                            {product?.foodType === 'VEG/VEGAN' ? (
                                              <Stack direction={'row'}>
                                                <img className='veg-nonveg' src={Images.veg} alt='veg' />
                                                <img style={{ height: '17px', marginRight: '4px', marginLeft: '0px' }} className='veg-nonveg' src={Images.vegan} alt='vegan' />
                                              </Stack>
                                            ) : (
                                              <img className='veg-nonveg' src={product?.foodType === 'VEG' ? Images.veg : (product?.foodType === 'VEGAN' ? Images.vegan : Images.nonVeg)} alt={product?.foodType === 'VEG' ? 'veg' : (product?.foodType === 'VEGAN' ? 'vegan' : 'non-veg')} />
                                            )}
                                          </Grid>
                                          <Grid item xs={10}>
                                            {getProductQuantityInCart(product._id) > 0 ? (
                                              <Box className="addedBtn" onClick={() => handleProductClick(product)}>
                                                {getProductQuantityInCart(product._id)} Added
                                              </Box>
                                            ) : (
                                              <Box className={product?.isExhausted ? "exhaustedBtn" : "notAddedBtn"} onClick={!product?.isExhausted ? () => handleProductClick(product) : null}>
                                                {product?.isExhausted ? "Out Of Stock" : "ADD"}
                                              </Box>
                                            )}
                                          </Grid>
                                        </Grid>
                                      </Box>
                                    </Hidden>
                                    <Hidden smDown>
                                      <Grid container className="typeAndFavouriteBox" onClick={() => handleProductClick(product)} direction={'row'} justifyContent={'space-between'}>
                                        <Grid item sm={6} md={10} sx={{ display: 'flex', alignItems: 'center' }}>
                                          <>
                                            {product?.foodType === 'VEG/VEGAN' ? (
                                              <Stack direction={'row'}>
                                                <img className='veg-nonveg' src={Images.veg} alt='veg' />
                                                <img style={{ height: '17px', marginRight: '4px', marginLeft: '0px' }} className='veg-nonveg' src={Images.vegan} alt='vegan' />
                                              </Stack>
                                            ) : (
                                              <img className='veg-nonveg' src={product?.foodType === 'VEG' ? Images.veg : (product?.foodType === 'VEGAN' ? Images.vegan : Images.nonVeg)} alt={product?.foodType === 'VEG' ? 'veg' : (product?.foodType === 'VEGAN' ? 'vegan' : 'non-veg')} />
                                            )}
                                          </>
                                        </Grid>
                                        <Grid item sm={6} md={2} sx={{ }}>
                                          {isProductInWishlist(product._id) ? (
                                            <img src={Images.heartFilled} alt="heartIconFilled" />
                                          ) : (
                                            <img src={Images.heartIcon} alt="heartIconFilled" />
                                          )}
                                        </Grid>
                                      </Grid>
                                    </Hidden>
                                    <Hidden smDown>
                                      <Box className="imageBox" onClick={() => handleProductClick(product)}>
                                        <img className="image" onClick={() => handleProductClick(product)} src={product?.displayImageUrl} alt="Product" onError={(e) => { e.target.onerror = null; e.target.src = Images.xpanseNavLogo; }} />
                                      </Box>
                                    </Hidden>
                                    <Hidden mdDown>
                                      <Stack onClick={() => handleProductClick(product)} direction={'column'}>
                                        <Typography className="productName">{product?.name}</Typography>
                                        <Stack direction={'row'} sx={{ alignItems: 'center' }}>
                                          <Typography className="productPrice">₹{product?.offerPrice} <span className='productMrp'> ₹{product?.mrp}</span></Typography>
                                          <Typography className="productOff">{product?.offerPercentage?.toFixed(0)}% OFF</Typography>
                                        </Stack>
                                      </Stack>
                                    </Hidden>
                                    <Hidden smDown>
                                      <Box className="ratingAndButton">
                                        <Typography className="productRating">{product?.weight} {product?.weightUnit}</Typography>
                                        {getProductQuantityInCart(product._id) > 0 ? (
                                          <Box className="addedBtn" onClick={() => handleProductClick(product)}>
                                            {getProductQuantityInCart(product._id)} Added
                                          </Box>
                                        ) : (
                                          <Box className={product?.isExhausted ? "exhaustedBtn" : "notAddedBtn"} onClick={!product?.isExhausted ? () => handleProductClick(product) : null}>
                                            {product?.isExhausted ? "Out Of Stock" : "ADD"}
                                          </Box>
                                        )}
                                      </Box>
                                    </Hidden>
                                  </Grid>
                                </Grid>
                              </Box>
                          </Grid>
                          </>);
                      })}
                    </Grid>
                  )})}
              </Grid>
            </Box>
            <ProductDrawer
              open={!!selectedProduct}
              onClose={handleCloseModal}
              selectedProduct={selectedProduct}
              handleAddToCart={handleAddToCart}
              isFavorited={isFavorited}
              handleFavoriteClick={handleFavoriteClick}
              quantity={quantity}
              handleIncreaseQuantity={handleIncreaseQuantity}
              handleDecreaseQuantity={handleDecreaseQuantity}
              selectedAddons={selectedAddons}
              handleAddonSelect={handleAddonSelect}
              totalPrice={totalPrice}
              totalMrpPrice={totalMrpPrice}
              offerPercentage={offerPercentage}
              addingToCart={addingToCart}
            />
            <Dialog open={loginModalOpen} onClose={() => setLoginModalOpen(false)}>
              <Box p={3}>
                <Typography variant="h5">Login Required</Typography>
                <Typography>Please login to use this feature.</Typography>
                <Button onClick={() => setLoginModalOpen(false)}>Close</Button>
                <Hidden mdUp>
                  <Button onClick={() => navigate('/mobileNumber')}>Login Now</Button>
                </Hidden>
              </Box>
            </Dialog>
          </Box>        
          {/* <Box className="product-listing-curve">
            <Box className="product-listing-curve-inside-box"></Box>
          </Box> */}
          <Box className="product-listing-line"></Box>
        </React.Fragment>
      )}
      </Box>
      <AuthModals openAuth={isModalOpen} handleCloseAuth={handleCloseAuth} />
    </>      
  );
};

export default ProductsListing;

{/* <Grid item sm={12} md={4} key={product._id}>
                              <Box key={product?._id}
                                sx={{
                                  ...(matchesMdUp ? { borderRight: isLastInRow ? 'none' : '1px dashed #D6D6D6', } : { borderRight: 'none', borderLeft: 'none', }),
                                  borderBottom: isLastRow ? 'none' : '1px dashed #D6D6D6',
                                  paddingBottom: (index === categories.length - 1 && totalProductsRendered === category.products.length) ? '20px' : '35px',
                                  ...(matchesMdUp ? {} : {
                                    borderBottom: productIndex === category.products.length - 1 ? 'none' : '1px dashed #6a6a6a',
                                    paddingBottom: productIndex === category.products.length - 1 ? '20px' : '35px'
                                  })
                                }}  
                                className={`product-item ${product.isExhausted ? 'exhausted' : ''}`}
                              >
                                <Grid container sx={{ alignItems: 'center' }}>
                                  <Hidden smUp>
                                    <Grid item sm={3} md={3} className="imageBox" onClick={() => handleProductClick(product)}>
                                      <img className="image" src={product?.displayImageUrl} alt="Product" />
                                    </Grid>
                                  </Hidden>
                                  <Grid item sm={9} md={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Grid container className="typeAndFavouriteBox" onClick={() => handleProductClick(product)} direction={'row'} justifyContent={'space-between'}>
                                      <Grid item sm={6} md={10} sx={{ display: 'flex', alignItems: 'center' }}>
                                        <>
                                          {product?.foodType === 'VEG/VEGAN' ? (
                                            <Stack direction={'row'}>
                                              <img className='veg-nonveg' src={Images.veg} alt='veg' />
                                              <img style={{ height: '17px', marginRight: '4px', marginLeft: '0px' }} className='veg-nonveg' src={Images.vegan} alt='vegan' />
                                            </Stack>
                                          ) : (
                                            <img className='veg-nonveg' src={product?.foodType === 'VEG' ? Images.veg : (product?.foodType === 'VEGAN' ? Images.vegan : Images.nonVeg)} alt={product?.foodType === 'VEG' ? 'veg' : (product?.foodType === 'VEGAN' ? 'vegan' : 'non-veg')} />
                                          )}
                                        </>
                                      </Grid>
                                      <Grid item sm={6} md={2} sx={{ }}>
                                        {isProductInWishlist(product._id) ? (
                                          <img src={Images.heartFilled} alt="heartIconFilled" />
                                        ) : (
                                          <img src={Images.heartIcon} alt="heartIconFilled" />
                                        )}
                                      </Grid>
                                    </Grid>
                                    <Hidden smDown>
                                      <Box className="imageBox" onClick={() => handleProductClick(product)}>
                                        <img className="image" onClick={() => handleProductClick(product)} src={product?.displayImageUrl} alt="Product" onError={(e) => { e.target.onerror = null; e.target.src = Images.xpanseNavLogo; }} />
                                      </Box>
                                    </Hidden>
                                    <Stack onClick={() => handleProductClick(product)} direction={'column'}>
                                      <Typography className="productName">{product?.name}</Typography>
                                      <Stack direction={'row'} sx={{ alignItems: 'center' }}>
                                        <Typography className="productPrice">₹{product?.offerPrice} <span className='productMrp'> ₹{product?.mrp}</span></Typography>
                                        <Typography className="productOff">{product?.offerPercentage?.toFixed(0)}% OFF</Typography>
                                      </Stack>
                                    </Stack>
                                    <Box className="ratingAndButton">
                                      <Typography className="productRating">{product?.weight} {product?.weightUnit}</Typography>
                                      {getProductQuantityInCart(product._id) > 0 ? (
                                        <Box className="addedBtn" onClick={() => handleProductClick(product)}>
                                          {getProductQuantityInCart(product._id)} Added
                                        </Box>
                                      ) : (
                                        <Box className={product?.isExhausted ? "exhaustedBtn" : "notAddedBtn"} onClick={!product?.isExhausted ? () => handleProductClick(product) : null}>
                                          {product?.isExhausted ? "Out Of Stock" : "ADD"}
                                        </Box>
                                      )}
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                          </Grid> */}