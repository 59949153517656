import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Hidden, Drawer, useMediaQuery } from '@mui/material';
import Images from '../../utils/Images';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API } from '../../utils/Api';
import gsap from 'gsap';
import { useDispatch, useSelector } from 'react-redux';
import { setData } from '../../store/Cart/cartSlice';
import { ArrowForward, ArrowForwardIos } from '@mui/icons-material';
import DeliveryScreen from './DeliveryScreen';
import DeliveryOutletScreen from './DeliveryScreens/DeliveryOutletScreen';
import PickupOutletScreen from './PickupScreens/PickupOutletScreen';

function AdvertiseScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userData, setUserData] = useState(null);
  const token = localStorage.getItem('token');
  const [clickableAdvertise, setClickableAdvertise] = useState([]);
  const [nonClickableAdvertise, setNonClickableAdvertise] = useState([]);
  const [outletData, setOutletData] = useState([]);
  let [isCartEmpty, setIsCartEmpty] = useState(true);
  const data = useSelector(state => state?.cart?.data);
  const [timeOfDay, setTimeOfDay] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [deliveryDrawerOpen, setDeliveryDrawerOpen] = useState(false);
  const matchesMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));

  const animateBoxes = () => {
    gsap.fromTo('.deliveryContainer', { scale: 1 }, { scale: 1.1, duration: 0.5, yoyo: true, repeat: 1 });
    gsap.fromTo('.pickupContainer', { scale: 1 }, { scale: 1.1, duration: 0.5, yoyo: true, repeat: 1 });
  };

  const handleOptionSelect = (option) => {
    localStorage.setItem('selectedOrderType', option);
    console.log(option);
    if (option === 'DELIVERY') {
      if (matchesMdUp) {
        setDeliveryDrawerOpen(true);
      } else {
        navigate('/deliveryScreen');
      }
    } else {
      if (matchesMdUp) {
        setDrawerOpen(true);
      } else {
        navigate('/pickupOutletSreen');
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // const userDataLogin = JSON.parse(localStorage.getItem('userDataLogin'));
    setUserData(JSON.parse(localStorage.getItem('userDataLogin')));
    getAdvertisments();
    setOutletData(JSON.parse(localStorage.getItem('selectedStoreData')));

    const currentTime = new Date().getHours();
    if (currentTime >= 5 && currentTime < 12) {
      setTimeOfDay('Morning');
    } else if (currentTime >= 12 && currentTime < 17) {
      setTimeOfDay('Afternoon');
    } else {
      setTimeOfDay('Evening');
    }
  }, []);

  const getAdvertisments = async () => {
    try{
      const response = await axios.get(`${API.BASE_URL}advertisement/user`);
      // console.log('Clickable Ads', response.data.data.advertisements.clickableAdvertisements);
      // console.log('Non Clickable Ads', response.data.data.advertisements);
      // setClickableAdvertise(response?.data?.data?.advertisements?.clickableAdvertisements);
      setNonClickableAdvertise(response?.data?.data?.advertisements?.nonClickableAdvertisements);
    } catch (error) {
      console.log("Error in getting Ads", error.response);
    }
  };

  const handleAdClick = () => {
    animateBoxes();
    window.scrollTo({ top: 0, behavior: 'smooth' }); 
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    const selectedStoreData = localStorage.getItem('selectedStoreData');
    if (!selectedStoreData && !open) {
      return;
    }
    setDrawerOpen(open);
  };

  const toggleDeliveryDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    const deliveryLatitude = localStorage.getItem("deliveryLatitude");
    const deliveryLongitude = localStorage.getItem("deliveryLongitude");
    const pickedAddress = localStorage.getItem("pickedAddress");
    const selectedStoreData = localStorage.getItem('selectedStoreData');
    if ((!deliveryLatitude || !deliveryLongitude || !pickedAddress || !selectedStoreData) && !open) {
      return;
    }
    setDeliveryDrawerOpen(open);
  };

  const [showScrollbar, setShowScrollbar] = useState(false);
  const advertiseScreenRef = useRef(null);

  const handleFocus = () => {
    setShowScrollbar(true);
  };

  const handleBlur = () => {
    setShowScrollbar(false);
  };

  const handleMouseEnter = () => {
    setShowScrollbar(true);
  };

  const handleMouseLeave = () => {
    setShowScrollbar(false);
  };

  return (
    <>
    <Hidden mdUp>
      <Box className="advertiseScreen">
        <div className='homeMain'>
          <div className='slider-container'>
            {nonClickableAdvertise?.filter(ad => ad?.isTopImage).length > 0 ? (
            nonClickableAdvertise.filter(ad => ad?.isTopImage).map((ad, index) => (
                <div key={index}>
                  <img src={ad?.adImagePath || Images.adBanner} alt="Ad-Image-Banner" className='homeBannerImage' />
                </div>
              ))
            ) : (
            <div>
              <img src={Images.homeCover} alt="Fallback Image" className='homeBannerImage'  />
            </div> )}
          </div>
          <Box className="homeContainer">
            {/* <Typography className="welcome">Welcome to Xpanse</Typography> */}
            <div className='homeContent'>
              <Box>
                <Typography className='homeProfileName'>Hello, {userData?.name ? userData.name : 'User'}!</Typography>
                <Typography className='homeDesc'>Good {timeOfDay}, let's find you<br/> the best cup of coffee!</Typography>
              </Box>
              <Box>
                <img
                  src={ userData?.profileImage ? userData?.profileImage : (userData?.gender === 'MALE' ? Images.maleAvatar : (userData?.gender === 'FEMALE' ? Images.femaleAvatar : Images.navProfile)) }
                  // src={ userData?.profileImage || Images.profileHead } 
                  className='profileImg' alt="" 
                />
              </Box>
            </div>
            <div className='selectOptionSection'>
              <Typography sx={{ fontSize: '17px', color: '#0E4961', textAlign: 'center', padding: '0px 0 40px 0', fontWeight: '500' }}>Please Select</Typography>
              <Box className='deliveryContainer' sx={{ backgroundColor: '#DFEEF4' }} 
                onClick={() => {
                  handleOptionSelect('PICKUP');
                  navigate('/pickupOutletScreen');
                }}
              // onClick={() => handleOptionSelect('PICKUP'), navigate('/pickupOutletScreen')}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography className="pickupText">Self Pick-Up</Typography>
                  <ArrowForwardIos sx={{ color: '#0E4961', fontSize: '18px' }} />
                </Box>
                <img className="pickupBox" style={{ objectFit: 'cover' }} src={Images.pickupOption} />
              </Box>
              <Box className='deliveryContainer' sx={{ backgroundColor: '#cecece' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box>
                    <Typography className="deliveryText" sx={{ textAlign: 'left' }}>Delivery</Typography>
                    <Typography sx={{ fontSize: '14px', marginLeft: '-8px' }}>Coming soon...</Typography>
                  </Box>
                  {/* <ArrowForwardIos sx={{ color: '#0E4961' }} /> */}
                </Box>
                <img className="deliveryBox" src={Images.deliveryOption} />
              </Box>
            </div>
            {nonClickableAdvertise?.filter(ad => ad?.isBottomImage).length > 0 ? (
              nonClickableAdvertise.filter(ad => ad?.isBottomImage).map((ad, index) => (
                <div className='adImageBox' key={index}>
                  <img onClick={handleAdClick} loading='lazy' className="adImage" src={ad?.adImagePath || Images.adBanner} alt={`Advertisement ${index}`} />
                </div>
              ))
            ) : (
              <div className='adImageBox'>
                <img loading='lazy' className="adImage" src={Images.adBanner} alt="Fallback Advertisement" />
              </div>
            )}
          </Box>
        </div>
      </Box>
    </Hidden>
    <Hidden mdDown>
      <Box ref={advertiseScreenRef}
        className={`advertiseScreen ${showScrollbar ? "show-scrollbar" : ""}`}
        tabIndex="0"
        onFocus={handleFocus}
        onBlur={handleBlur}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className='homeMain'>
          <div className='slider-container'>
            {nonClickableAdvertise?.filter(ad => ad?.isTopImage).length > 0 ? (
              nonClickableAdvertise.filter(ad => ad?.isTopImage).map((ad, index) => (
                  <div key={index}>
                    <img src={ad?.adImagePath || Images.adBanner} alt="Ad-Image-Banner" className='homeBannerImage' />
                  </div>
                ))
              ) : (
              <div>
                <img src={Images.homeCover} alt="Fallback Image" className='homeBannerImage'  />
              </div> )}
          </div>
          <Typography className="welcome">Welcome to Xpanse</Typography>
          <Box className='homeContent' sx={{ padding: '0 40px 10px 40px'}}>
            <Box>
              <Typography className='homeProfileName'>Hello, {userData?.name ? userData.name : 'User'}!</Typography>
              <Typography className='homeDesc'>Good {timeOfDay}, let's find you<br/> the best cup of coffee!</Typography>
            </Box>
            <Box>
              <img src={ userData?.profileImage || Images.profileHead } className='profileImg' alt="" />
            </Box>
          </Box>
          <Box className="homeContainer">
            <div className='selectOptionSection'>
              <Typography sx={{ fontSize: '17px', color: '#186B8C', textAlign: 'center' }}>Please Select</Typography>
              <Box className='deliveryContainer' sx={{ backgroundColor: '#DFEEF4', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }} onClick={() => handleOptionSelect('PICKUP')}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography className="deliveryText">Self Pick-Up</Typography>
                  <ArrowForwardIos sx={{ color: '#0E4961' }} />
                </Box>
                <img className="deliveryBox" src={Images.pickupOption} alt='pickup' loading='lazy' />
              </Box>
              {/* <Box className='deliveryContainer' sx={{ backgroundColor: '#cecece', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }} onClick={() => handleOptionSelect('DELIVERY')}> */}
              <Box className='deliveryContainer' sx={{ backgroundColor: '#cecece', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }} onClick={() => handleOptionSelect('DELIVERY')}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box>
                    <Typography className="deliveryText"  sx={{ textAlign: 'left'}}>Delivery</Typography>
                    <Typography sx={{ fontSize: '14px', marginLeft: '-8px' }}>Coming soon...</Typography>
                  </Box>
                  {/* <ArrowForwardIos sx={{ color: '#0E4961' }} /> */}
                </Box>
                <img className="deliveryBox" src={Images.deliveryOption} alt='delivery' loading='lazy' />
              </Box>
            </div>
          </Box>
        </div>
      </Box>
    </Hidden>
    <Drawer
      anchor="left"
      open={drawerOpen}
      onClose={toggleDrawer(false)}
      PaperProps={{ className: "your-drawer-class" }}
    >
      <Box sx={{ overflow: 'auto' }} className="hide-scrollbar">
        <PickupOutletScreen />
      </Box>
    </Drawer>
    <Drawer
      anchor="left"
      open={deliveryDrawerOpen}
      onClose={toggleDeliveryDrawer(false)}
      PaperProps={{ 
        sx: { 
          height: '100%',
          overflow: 'auto',
          backgroundColor: '#DFEEF4',
          '&::-webkit-scrollbar': {
            display: 'none'
          },
        }
      }}
    >
      <Box>
        <DeliveryScreen />
      </Box>
    </Drawer>
    </>
  )
}

export default AdvertiseScreen;
