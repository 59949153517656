import { Box, Button, Drawer, Grid, Hidden, IconButton, InputAdornment, Stack, TextField, Typography, CircularProgress } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { API } from '../../utils/Api';
import Images from '../../utils/Images';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import { SearchBar } from '../../components/SearchBar';
import { CloseOutlined, KeyboardArrowDown, KeyboardArrowLeft } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { setData } from '../../store/Cart/cartSlice';
import { Player } from '@lottiefiles/react-lottie-player';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddonIconComponent from '../../components/AddonIconComponent';

const WishListScreen = () => {
  const token = localStorage.getItem("token");
  const [wishlistData, setWishlistData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const navigate = useNavigate();
  const [outletData, setOutletData] = useState(JSON.parse(localStorage.getItem('selectedStoreData')));
  const outlet = localStorage.getItem("selectedStoreData");
  const deliveryType = localStorage.getItem("selectedOrderType");
  const outletObject = JSON.parse(outlet);
  const outletId = outletObject?._id;
  const dispatch = useDispatch();
  const [selectedAddons, setSelectedAddons] = useState({});
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [cartData, setCartData] = useState([]);
  const [isFavorited, setIsFavorited] = useState(false);
  const cartDataRedux = useSelector(state => state?.cart?.data);
  const [addingToCart, setAddingToCart] = useState(false);

  const getWishlist = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}wishlist/getUserWishlist/${outletData._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setWishlistData(response?.data?.data?.wishLists);
      console.log('Wishlist Screen Data', wishlistData);
    } catch (error) {
      console.log('Wishlist Screen Error', error?.response?.data);
    }
  };

  useEffect(() => {
    getWishlist();
    setOutletData(JSON.parse(localStorage.getItem('selectedStoreData')));
  },[]);

  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
    console.log(searchText);
  };

  const fetchUserCartData = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}cart/userCart/${outletData._id}/${deliveryType}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(setData(response?.data));
      setCartData(cartDataRedux);
      console.log('Cart Screen Data', response?.data);
    } catch (error) {
      dispatch(setData([]));
      console.log('Cart Screen Error', error?.response?.data);
    }
  };

  const handleAddToCart = async () => {
    setAddingToCart(true);
    try {
        const addonsArray = Object.keys(selectedAddons).map((addonId) => ({
            _id: addonId,
            selectedValue: selectedAddons[addonId].value,
            addonValueId: selectedAddons[addonId]._id,
        }));
        const defaultAddonsArray = selectedProduct.addons && Array.isArray(selectedProduct.addons)
        ? selectedProduct.addons
            .filter(addon => addon?.addonValues?.some(value => value?.isDefault && !selectedAddons[addon?._id]))
            .map(addon => {
                const defaultAddonValue = addon?.addonValues?.find(value => value?.isDefault);
                return {
                    _id: addon._id,
                    selectedValue: defaultAddonValue.value,
                    addonValueId: defaultAddonValue._id,
                };
            })
        : [];
        const allAddonsArray = [...addonsArray, ...defaultAddonsArray];
        const cartItem = {
            ordersType: deliveryType,
            outlet: outletId,
            cartItems: [
                {
                  product: selectedProduct._id,
                  quantity: quantity,
                  addons: allAddonsArray.length > 0 ? allAddonsArray : undefined,
                }
            ],
        };
        const response = await axios.post(`${API.BASE_URL}cart`, cartItem, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        handleCloseModal();
        fetchUserCartData();
        setAddingToCart(false);
        toast.success('Product added to cart!!!');
        console.log(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setAddingToCart(false);
    }
  };

  const handleCloseModal = () => {
    setSelectedProduct(null);
    setQuantity(1);
    setSelectedAddons({});
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product.product);
    setIsFavorited(isProductInWishlist(product._id));
    console.log('Product', product);
  };
  
  const isProductInWishlist = (productId) => {
    return wishlistData.includes(productId);
  };

  const handleAddonSelect = (addonId, addonValue) => {
    setSelectedAddons((prevSelected) => ({
      ...prevSelected,
      [addonId]: addonValue,
    }));
  };

  const handleFavoriteClick = async () => {
    try {
      const response = await axios.post(
        `${API.BASE_URL}wishlist/remove`, 
        { productId: selectedProduct._id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsFavorited(false);
      toast.success('Product removed from favorites!!!');
      console.log("Wishlist Remove Success", response.data);
      getWishlist();
      handleCloseModal();
    } catch (error) {
      console.error(error);
    }
  };

  const [totalPrice, setTotalPrice] = useState(selectedProduct?.offerPrice || 0);
  const [totalMrpPrice, setTotalMrpPrice] = useState(selectedProduct?.mrp || 0);
  const [offerPercentage, setOfferPercentage] = useState(0);

  useEffect(() => {
    let calculateTotalPrice = () => {
      let price = selectedProduct?.offerPrice ?? 0;
      let mrp = selectedProduct?.mrp ?? 0;
      Object.values(selectedAddons).forEach(addon => {
        price += addon.offerPrice ?? 0;
        mrp += addon.mrp ?? 0;
      });
      setTotalPrice(price);
      setTotalMrpPrice(mrp);
    };
    calculateTotalPrice();
  }, [selectedAddons, selectedProduct]);

  useEffect(() => {
    if (totalMrpPrice > 0 && totalPrice < totalMrpPrice) {
      const discount = ((totalMrpPrice - totalPrice) / totalMrpPrice) * 100;
      setOfferPercentage(discount);
    } else {
      setOfferPercentage(0);
    }
  }, [totalPrice, totalMrpPrice]);

  const handleIncreaseQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const getRandomRating = () => {
    return (Math.random() * (4.2 - 3.7) + 3.7).toFixed(1);
  };

  const getProductQuantityInCart = (productId) => {
    let totalQuantity = 0;
    cartDataRedux?.data?.cartItems?.forEach(item => { if (item.product._id === productId) { totalQuantity += item.quantity; }});
    return totalQuantity;
  };

  return (
    <Box className="wishlist">
      {wishlistData.length === 0 ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh'}}>
          <Player
            autoplay
            loop={true}
            src={Images.emptyCart}
            className="emptyCart"
          />
        </Box>
      ) : (
        <>
          <Box>
            <Hidden mdUp>
              <KeyboardArrowLeft onClick={() => navigate('/profileScreen')} />
            </Hidden>
            <Typography className="title" sx={{ paddingBottom: '10px' }}>Favourites</Typography>
          </Box>
          {/* <Box className="searchBar">
            <TextField
              placeholder={"Search for your favourites"}
              className="bar"
              variant="outlined"
              size="small"
              fullWidth
              value={searchText}
              onChange={handleSearchTextChange} 
              sx={{ backgroundColor: '#fff'}}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton style={{ color: '#1DA1F2' }}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box> */}
          <Box className="productsBox">
            {wishlistData.map((product, index) => (
              <>
              <div onClick={() => handleProductClick(product)} key={product?.product?._id} className="favProduct">
                <Box item xs={3} className="imageBox">
                  <img className="image" src={product?.product?.displayImageUrl} />
                  {/* <img src={product?.product?.displayImageUrl} alt={product?.product?.name} /> */}
                </Box>
                <div className='favProductContent'>
                  <div className='favProductIcon'>
                    <div className='vegAndOfferContainer'>
                        <>
                          {product?.product?.foodType === 'VEG/VEGAN' ? (
                            <Stack direction={'row'}>
                              <img style={{ height: '17px', marginRight: '4px' }}  className='veg-nonveg' src={Images.veg} alt='veg' />
                              <img style={{ height: '17px', marginRight: '4px' }} className='veg-nonveg' src={Images.vegan} alt='vegan' />
                            </Stack>
                          ) : (
                            <img style={{ height: '17px' }}  className='veg-nonveg' src={product?.product?.foodType === 'VEG' ? Images.veg : (product?.product?.foodType === 'VEGAN' ? Images.vegan : Images.nonVeg)} alt={product?.product?.foodType === 'VEG' ? 'veg' : (product?.product?.foodType === 'VEGAN' ? 'vegan' : 'non-veg')} />
                          )}
                        </>
                        <Typography className='bestOfferText'> <i>{product?.product?.productTag?.tagName}</i> </Typography>
                    </div>
                    <img onClick={handleFavoriteClick} style={{ cursor: 'pointer' }} src={Images.heartFilled} alt="heartIconFilled" />
                  </div>
                  <Typography className="favProductName">{product?.product?.name}</Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <div className='offerContain'>
                      <Typography className='offerPrice'>₹{product?.product?.offerPrice}</Typography>
                      <Typography className='price'>₹{product?.product?.mrp}</Typography>
                    </div>
                    <Typography className="productOff">{product?.product?.offerPercentage?.toFixed(0)}% OFF</Typography>
                  </Box>
                  <div className='rateAndButton'>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <StarRoundedIcon sx={{ fontSize: '16px' }} />
                      <Typography sx={{ display: 'flex', paddingLeft: '4px' }}>{product?.product?.productRatings?.averageProductRatings ? product?.product.productRatings.averageProductRatings.toFixed(1) : 4.2}</Typography>
                    </Box>
                    {/* <Button className='addButton'>ADD</Button> */}
                    {getProductQuantityInCart(product._id) > 0 ? (
                      <Box className="addedButton" onClick={() => handleProductClick(product)}>
                        {getProductQuantityInCart(product._id)} Added
                      </Box>
                    ) : (
                      <Box sx={{ marginRight: '15px' }} className={product?.product?.isExhausted ? "exhaustedBtnWishlist" : "notAddedBtn"} onClick={!product?.product?.isExhausted ? () => null : null}>
                        {product?.product?.isExhausted ? "Out Of Stock" : "ADD"}
                      </Box>
                    )}
                  </div>
                </div>
              </div>
              </>
            ))}
          </Box>
          <Drawer
            open={!!selectedProduct}
            onClose={handleCloseModal}
            anchor="bottom"
          >
            <Box className="productDrawer">
              <IconButton
                onClick={handleCloseModal}
                sx={{ position: 'absolute', top: -20, right: 8, zIndex: 1, backgroundColor: '#fff', height: '16px', width: '16px' }}
              >
                <CloseOutlined sx={{ fontSize: '12px' }} />
              </IconButton>
              <Grid container>
                <Grid item xs={5} sx={{ justifyContent: 'center'}}>
                  <Box className="imageBox">
                    <img className="image" src={selectedProduct?.displayImageUrl} alt="Product" />
                  </Box>
                </Grid>
                <Grid item xs={7} sx={{ paddingLeft: '2%'}}>
                  <Box className="drawerVegAndFavourite">
                    <Box sx={{ display: 'flex' }}>
                      <Typography className="productRating" sx={{ textAlign: 'right'}}><StarRoundedIcon className="star"/>{selectedProduct?.productRatings?.averageProductRatings ? selectedProduct.productRatings.averageProductRatings.toFixed(1) : 4.2}</Typography>
                      {/* <Typography className="productRating" sx={{ textAlign: 'right'}}><StarRoundedIcon className="star"/>{selectedProduct?.productRatings?.averageProductRatings.toFixed(1) : getRandomRating()}</Typography> */}
                      <>
                        {selectedProduct?.foodType === 'VEG/VEGAN' ? (
                          <Stack direction={'row'}>
                            <img   className='vegNonveg' src={Images.veg} alt='veg' />
                            <img style={{ height: '20px', marginRight: '4px' }} className='vegNonveg' src={Images.vegan} alt='vegan' />
                          </Stack>
                        ) : (
                          <>
                          {selectedProduct && (
                            <img style={{ height: '20px' }} className='vegNonveg' src={selectedProduct.foodType === 'VEG' ? Images.veg : (selectedProduct.foodType === 'VEGAN' ? Images.vegan : Images.nonVeg)} alt={selectedProduct.foodType === 'VEG' ? 'veg' : (selectedProduct.foodType === 'VEGAN' ? 'vegan' : 'non-veg')} />
                          )} 
                          </>                         
                        )}
                      </>
                    </Box>
                    <FavoriteRoundedIcon className="favouriteMarked" onClick={handleFavoriteClick} />
                  </Box>
                  <Box className="productDetails">
                    {/* <Typography className="productTag">{selectedProduct.productTag[0].tagName}</Typography> */}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography className="productName" sx={{ textAlign: 'left'}}>{selectedProduct?.name}</Typography>
                    </Box>
                    <Typography className="productInfo" sx={{ paddingBottom: '0px' }}>{selectedProduct?.calories} cal {selectedProduct?.weight}<span style={{ textTransform: 'capitalize' }}>{selectedProduct?.weightUnit}</span> </Typography>
                    <Typography className="productInfo">{selectedProduct?.description}</Typography>
                  </Box> 
                </Grid>
              </Grid>
              {selectedProduct && (
                <>
                  {selectedProduct.hasAddon && selectedProduct.addons && selectedProduct.addons.length > 0 && (
                    <Box className="addonsBox">
                      {selectedProduct.addons.map((addon) => (
                        <Grid container key={addon._id} sx={{ borderBottom: '1px solid #B3B3B3', padding: '10px 0 7px 0' }}>
                          <Grid item xs={3}>
                            <Typography className="addonTitle">{addon.addonTitle}</Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <Grid container justifyContent="flex-end">
                              {addon.addonValues.map((addonValue) => {
                                const isSelected = selectedAddons[addon._id]?._id === addonValue._id || (!selectedAddons[addon._id] && addonValue.isDefault);
                                const icon = addonValue.iconInfo ? <AddonIconComponent iconInfo={addonValue.iconInfo} selected={isSelected} /> : null;
                                return (
                                  <Grid item xs={4} key={addonValue._id} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Box 
                                      className="addonItem" 
                                      onClick={() => handleAddonSelect(addon._id, { ...addonValue, offerPrice: addonValue.offerPrice ?? 0, mrp: addonValue.mrp ?? 0 })}  
                                      sx={{ display: 'flex', flexDirection: 'column' }}
                                    >
                                      <Box sx={{ flex: '1 1 auto' }} />
                                        <Typography className="addonValue" sx={{ alignSelf: 'center', color: '#00A4E2' }}> {addonValue.subLabel} </Typography>
                                        {icon ? (
                                          <Box className="addonIcon" sx={{ margin: 0, padding: 0 }}> {icon} </Box>
                                        ) : (
                                          <Box className={`addonSelect ${isSelected ? 'selected' : ''}`} sx={{ alignSelf: 'center' }}> {addonValue.value ?? 0} </Box>
                                        )}
                                        <Typography className="addonValue" sx={{ alignSelf: 'center', color: '#118E06' }}> +₹{addonValue.offerPrice ?? 0} </Typography>
                                        {/* <Typography className="addonOutOfStock">Out Of Stock</Typography> */}
                                      </Box>
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Box>
                  )}
                </>
              )}
              <Box className="priceAndButton">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography className="productPrice">₹{parseFloat(totalPrice) * parseInt(quantity)}<span className='productMrp'> ₹{parseFloat(totalMrpPrice) * parseInt(quantity)}</span></Typography>
                  <Typography className="productOff">{offerPercentage?.toFixed(0)}% OFF</Typography>
                </Box>
                <Box className="addRemoveItem">
                  <RemoveCircleIcon className="text" onClick={handleDecreaseQuantity} />
                  <Typography sx={{ color: '#000' }} className="text">{quantity}</Typography>
                  <AddCircleIcon className="text" onClick={handleIncreaseQuantity} />
                </Box>
              </Box>
              {addingToCart ? (
                <Box className="button"><CircularProgress size={18} sx={{ color: '#fff' }} /> Adding to cart</Box>
              ) : (
                <Box className={selectedProduct?.isExhausted ? "exhaustedButton" : "button"} onClick={!selectedProduct?.isExhausted ? () => handleAddToCart() : null}>{selectedProduct?.isExhausted ? "Out Of Stock" : "ADD"}</Box>
              )}
            </Box>
          </Drawer>
        </>
      )}
    </Box>
  )
}

export default WishListScreen;
