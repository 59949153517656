import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, CircularProgress, Grid, Hidden, TextareaAutosize, Typography } from '@mui/material';
import { KeyboardArrowLeft } from '@mui/icons-material';
import { gsap } from 'gsap';
import Images from '../../utils/Images';
import star from '../../assets/images/starOutlined.svg';
import starFilled from '../../assets/images/starFilled.svg';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { API } from '../../utils/Api';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const ReviewScreen = ({ order: propOrder }) => {
  const location = useLocation();
  const orderDetails = propOrder || location.state?.order;
  const token = localStorage.getItem("token");
  const [akshataBtn, setAkshataBtn] = useState(true);
  const navigate = useNavigate();

  const [itemStates, setItemStates] = useState(orderDetails?.orderItems?.map(() => ({
    rating: 5,
    expanded: false,
    feedbackText: ''
  })));

  const feedbackRefs = useRef(orderDetails?.orderItems?.map(() => React.createRef()));

  const handleRating = (index, newRating) => {
    const newState = [...itemStates];
    newState[index] = {
      ...newState[index],
      rating: newRating,
      expanded: newRating <= 3
    };
    setItemStates(newState);
  };

  const handleFeedbackTextChange = (index, text) => {
    const newState = [...itemStates];
    newState[index] = {
      ...newState[index],
      feedbackText: text
    };
    setItemStates(newState);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    itemStates.forEach((item, index) => {
      if (item.expanded) {
        gsap.to(feedbackRefs.current[index].current, { maxHeight: 500, duration: 0.8, ease: "power2.out" });
      } else {
        gsap.to(feedbackRefs.current[index].current, { maxHeight: 0, duration: 0.8, ease: "power2.in" });
      }
    });
  }, [itemStates]);

  const handleSubmit = async () => {
    setAkshataBtn(true);
    const orderItemRatings = orderDetails.orderItems.map((item, index) => {
        const ratingObject = {
            "orderItem": item._id,
            "ratings": itemStates[index].rating,
        };
        if (itemStates[index].feedbackText) {
          ratingObject.feedback = itemStates[index].feedbackText;
        }
        return ratingObject;
    });
    const payload = {
      orderItemRatings: orderItemRatings,
      order: orderDetails.order._id,
    };
    // console.log(payload);
    try {
      const response = await axios.post(`${API.BASE_URL}order/ratings`, payload , {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      toast.success("Feedback submitted successfully");
      setAkshataBtn(false);
      // console.log("Review sent successfully", response);
    } catch (error) {
      console.error('Error while submitting review:', error);
      toast.error(error.response.data.error);
    } finally{
      setAkshataBtn(false);
    }

  };

  return (
    <Box className="reviewScreen">
      <Box>
        <Typography className="title">Feedback</Typography>
        <Hidden mdUp>
          <KeyboardArrowLeft onClick={() => navigate('/profileScreen')} className="backIcon" />
        </Hidden>
      </Box>
      <Box className="productsBox">
        <Typography className="orderTitle">How was your order?</Typography>
        {orderDetails?.orderItems?.map((item, index) => (
          <Grid container key={index} className="itemBox">
            <Grid item sm={4} sx={{ display: 'flex', alignItems: 'center'}}>
              <Box className="imageBox">
                <img className="image" src={item.product.displayImage} alt="product" />
              </Box>
            </Grid>
            <Grid item sm={8}>
              <Typography className="productName">{item.product.name}</Typography>
              {item.addons && item.addons.length > 0 && (
                <>
                  <Typography className="customText">Customization</Typography>
                  <Typography className="customText">{item.addons.map((addon, index) => addon.selectedValue).join(', ')}</Typography>
                </>
              )}
              <Box className="starBox">
                {Array.from({ length: 5 }, (_, starIndex) => (
                  <span key={starIndex} onClick={() => handleRating(index, starIndex + 1)}>
                    {itemStates[index].rating > starIndex ? <img className="star" src={starFilled} alt="starFilled" /> : <img className="star" src={star} alt="star" /> }
                  </span>
                ))}
              </Box>
            </Grid>
            <Grid ref={feedbackRefs.current[index]} style={{ overflow: 'hidden', maxHeight: 0 }} item sm={12}>
              <Box>
                <Typography className="sorryTitle">Sorry to hear that.</Typography>
                <TextareaAutosize
                  minRows={8}
                  className="reviewBox"
                  style={{ borderRadius: '8px', width: '77vw', borderColor: '#BEBEBE', borderWidth: '1px', borderStyle: 'solid' }}
                  placeholder=" What went wrong?"
                  value={itemStates[index].feedbackText}
                  onChange={(e) => handleFeedbackTextChange(index, e.target.value)}
                />
              </Box>
            </Grid>
          </Grid>
        ))}
        {akshataBtn ? (
          <Box className="button" sx={{ cursor: 'progress' }}>
            <CircularProgress size={18} sx={{ color: '#fff'}} />
            <Typography>Submitting</Typography>
          </Box>
        ) : (
          <Box className="button" onClick={handleSubmit} sx={{ cursor: 'pointer' }}>Submit</Box>
        )}

      </Box>
    </Box>
  );
};

export default ReviewScreen;
